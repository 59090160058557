import React from 'react';
import { useTranslation } from 'react-i18next'; // Importing useTranslation hook
import Fade from 'react-reveal/Fade';
import iphone from '../../assets/img/about/img.png';
import img from "../../assets/img/img(1).png";

const Main = () => {
  const { t } = useTranslation(); // Using useTranslation hook to access translations

  return (
    <div className='pb-[0px] xl:pb-[90px] flex'>
      <img src={img} alt="" className="imgggg animatedimgd"/>
      <div className='max-w-[1280px] flex flex-col-reverse xl:flex-row mx-auto relative z-10'>
        <div className="relative w-full xl:w-[525px]">
          <div className='pt-[25px] sm:pt-[125px] xl:pt-[120px]'>  
            <div className='h-[700px] xl:h-[500px] flex flex-col mx-auto'>
              <Fade duration={1000} top><h1 className='plus-jakarta text-[20px] sm:text-[28px] xl:text-[60px] text-white font-extrabold xl:mt-[50px] mx-auto xl:mx-0 text-center xl:text-left'>{t('main_title')}</h1></Fade>
              <Fade duration={1000} top><img src={iphone} alt="" className='block xl:hidden pt-[20px] mx-auto xl:mx-0 xl:ml-[130px] w-full h-[300px]'/></Fade>
              <Fade duration={2000} top><p className='sm:w-[688px] xl:w-full open-sans text-[14px] sm:text-[16px] xl:text-[18px] font-light text-white mt-[20px] text-center xl:text-left mx-[20px] xl:mx-0'>{t('main_description')}</p></Fade>
              <button className='w-[161px] h-[47px] bg-[#FFF62B] mt-[30px] rounded-lg mx-auto xl:mx-0'> 
                <a href="https://user.amberinvest.tech"><p className='text-[18px] font-medium open-sans text-black'>{t('start_trading_button')}</p></a>
              </button>
            </div>
          </div>
        </div>
        <Fade duration={1000} top><img src={iphone} alt="" className='hidden xl:block pt-0 xl:pt-[100px] mx-auto xl:mx-0 xl:ml-[130px] w-[300px] h-[50px] xl:w-[757.285px] xl:h-[600px]'/></Fade>
      </div>
    </div>
  );
};

export default Main;