import main from './assets/img/mainn.jpg';
import swiper1 from './assets/img/swiper1.png';
import swiper2 from './assets/img/swiper2.png';
import swiper3 from './assets/img/32133.png';


export const images = [
    main,
    swiper1,
    swiper2,
    swiper3,
];