import {  useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

import phoro from '../assets/img/termsandservice/Photo.jpg'

const TermsAndServices = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='bg-black z-10 relative xl:mt-[80px] overflow-hidden'>
        <div className='h-[455px] bg-white hidden xl:flex'>
          <div className='w-1/2 h-full bgbgbgbgfd relative z-10 flex flex-col justify-center items-end pr-[170px]'>
            <div>
              <div className='flex'>
                <div className='mt-[8px] w-[11px] h-[11px] bg-[#82279E]'></div>
                <p className='ml-[7px] text-white font-bold text-[18px] inter'>{t("terms.section28")}</p>
              </div>
              <h1 className='plus-jakarta text-[60px] text-white font-extrabold'>{t("terms.section7")}<br/> {t("terms.section42")}</h1>
            </div>
          </div>
          <img src={phoro} alt="" className='xl:absolute z-0 right-0'/>
        </div>
        <div className='flex flex-col xl:hidden'>
          <img src={phoro} alt="" className='object-cover'/>
          <div className='mx-[20px] mt-[40px]'>
            <div className='flex'>
              <div className='mt-[8px] w-[11px] h-[11px] bg-[#82279E]'></div>
              <p className='ml-[7px] text-white font-bold text-[18px] inter'>AMBER INVEST</p>
            </div>
            <h1 className='plus-jakarta text-[20px] mt-[10px] text-white font-extrabold'>{t("terms.section31")}</h1>
          </div>
        </div>
        <div className='bg-black relative z-10'>
          <div className='max-w-[1280px] mx-[20px] xl:mx-auto'>
            <p className='pt-[30px] xl:pt-[60px] text-white inter text-[14px] xl:text-[16px]'>{t("terms.section47")}</p>
            <p className='pt-[20px] text-white inter text-[14px] xl:text-[16px]'>{t("terms.section1")}</p>
            <p className='pt-[20px] text-white inter text-[14px] xl:text-[16px]'>{t("terms.section48")} </p>
            <h1 className='text-white pt-[50px] font-extrabold open-sans text-[28px] xl:text-[35px]'>{t("terms.section36")}</h1>
            <p className='pt-[30px] text-white inter text-[14px] xl:text-[16px]'>{t("terms.section13")}</p>
            <p className='pt-[20px] text-white inter text-[14px] xl:text-[16px]'>{t("terms.section35")}</p>
            <p className='pt-[20px] text-white inter text-[14px] xl:text-[16px]'>{t("terms.section23")}</p>
            <p className='pt-[20px] text-white inter text-[14px] xl:text-[16px]'>{t("terms.section15")}</p>
            <p className='pt-[20px] text-white inter text-[14px] xl:text-[16px]'>{t("terms.section14")} </p>
            <p className='pt-[20px] text-white inter text-[14px] xl:text-[16px]'>{t("terms.section21")}</p>
            <p className='pt-[20px] text-white inter text-[14px] xl:text-[16px]'>{t("terms.section37")}</p>
            <p className='pt-[20px] text-white inter text-[14px] xl:text-[16px]'>{t("terms.section18")}</p>
            <p className='pt-[20px] text-white inter text-[14px] xl:text-[16px]'>{t("terms.section12")} </p>
            <h1 className='text-white pt-[50px] font-extrabold open-sans text-[28px] xl:text-[35px]'>{t("terms.section27")}</h1>
            <p className='pt-[30px] text-white inter text-[14px] xl:text-[16px]'>{t("terms.section11")}</p>
            <p className='pt-[20px] text-white inter text-[14px] xl:text-[16px]'>{t("terms.section30")}</p>
            <p className='pt-[20px] text-white inter text-[14px] xl:text-[16px]'>{t("terms.section9")} </p>
            <h1 className='text-white pt-[50px] font-extrabold open-sans text-[28px] xl:text-[35px]'>{t("terms.section20")}</h1>
            <p className='pt-[30px] text-white inter text-[14px] xl:text-[16px]'>{t("terms.section2")}</p>
            <h1 className='text-white pt-[50px] font-extrabold open-sans text-[28px] xl:text-[35px]'>{t("terms.section43")}</h1>
            <p className='pt-[30px] text-white inter text-[14px] xl:text-[16px]'>{t("terms.section19")}</p>
            <h1 className='text-white pt-[50px] font-extrabold open-sans text-[28px] xl:text-[35px]'>{t("terms.section49")}</h1>
            <p className='pt-[30px] text-white inter text-[14px] xl:text-[16px]'>{t("terms.section17")}</p>
            <h1 className='text-white pt-[50px] font-extrabold open-sans text-[28px] xl:text-[35px]'>{t("terms.section24")}</h1>
            <p className='pt-[30px] text-white inter text-[14px] xl:text-[16px]'>{t("terms.section38")}</p>
            <p className='pt-[20px] text-white inter text-[14px] xl:text-[16px]'>{t("terms.section39")} </p>
            <h1 className='text-white pt-[50px] font-extrabold open-sans text-[28px] xl:text-[35px]'>{t("terms.section26")}</h1>
            <p className='pt-[30px] text-white inter text-[14px] xl:text-[16px]'>{t("terms.section40")} </p>
            <p className='pt-[20px] text-white inter text-[14px] xl:text-[16px]'>{t("terms.section22")} </p>
            <h1 className='text-white pt-[50px] font-extrabold open-sans text-[28px] xl:text-[35px]'>{t("terms.section41")}</h1>
            <p className='pt-[30px] text-white inter text-[14px] xl:text-[16px]'>{t("terms.section50")}</p>
            <h1 className='text-white pt-[50px] font-extrabold open-sans text-[28px] xl:text-[35px]'>{t("terms.section4")}</h1>
            <p className='pt-[30px] text-white inter text-[14px] xl:text-[16px]'>{t("terms.section6")}</p>
            <p className='pt-[20px] text-white inter text-[14px] xl:text-[16px]'> {t("terms.section44")}</p>
            <p className='pt-[20px] text-white inter text-[14px] xl:text-[16px]'> {t("terms.section45")} </p>
            <h1 className='text-white pt-[50px] font-extrabold open-sans text-[28px] xl:text-[35px]'>{t("terms.section4")}</h1>
            <p className='pt-[30px] text-white inter text-[14px] xl:text-[16px]'>{t("terms.section5")}</p>
            <p className='pt-[20px] text-white inter text-[14px] xl:text-[16px]'>{t("terms.section32")}</p>
            <p className='pt-[20px] text-white inter text-[14px] xl:text-[16px]'> {t("terms.section33")}</p>
            <p className='pt-[20px] text-white inter text-[14px] xl:text-[16px]'>  {t("terms.section34")} </p>
            <p className='pt-[20px] text-white inter text-[14px] xl:text-[16px]'> {t("terms.section3")}</p>
            <p className='pt-[20px] text-white inter text-[14px] xl:text-[16px]'>{t("terms.section46")}</p>
            <p className='pt-[20px] text-white inter text-[14px] xl:text-[16px]'>{t("terms.section52")}</p>
            <p className='pt-[20px] text-white inter text-[14px] xl:text-[16px]'>{t("terms.section10")}</p>
            <h1 className='text-white pt-[50px] font-extrabold open-sans text-[28px] xl:text-[35px]'>{t("terms.section8")}</h1>
            <p className='pt-[20px] text-white inter text-[14px] xl:text-[16px]'>{t("terms.section29")}</p>
            <p className='pt-[20px] text-white inter text-[14px] xl:text-[16px]'> {t("terms.section51")}</p>
            <p className='pt-[20px] text-white inter text-[14px] xl:text-[16px]'>  {t("terms.section25")}</p>
            <p className='xl:pb-[180px] pb-[110px] pt-[20px] text-white inter text-[14px] xl:text-[16px]'> {t("terms.section16")}</p>
            
           </div>
        </div>
    </div>
  );
};

export default TermsAndServices;