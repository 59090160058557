import React from 'react';
import Fade from 'react-reveal/Fade';
import { useTranslation } from "react-i18next";
import icon1 from '../../assets/img/icon1.png';
import icon2 from '../../assets/img/icon2.png';
import icon3 from '../../assets/img/icon3.png';
import icon4 from '../../assets/img/icon4.png';

const TradeMost = () => {
    const { t } = useTranslation();

    return (
        <div className='bg-black'>
            <Fade bottom><h1 className='open-sans text-[20px] sm:text-[28px] xl:text-[35px] text-white font-extrabold text-center'>{t('trade_most_title')}</h1></Fade>
            <div className='max-w-[1220px] xl:flex-row flex-col mt-[100px] gap-[250px] pb-[190px] sm:flex hidden mx-auto'>
                <div className='grid grid-cols-2 gap-[250px] mx-auto xl:mx-0'>
                    <Fade bottom delay={500}><div className='w-[300px]'>
                        <img src={icon1} alt="" className='w-[75px] mx-auto'/>
                        <p className='text-white text-center font-light montserrat mt-[37px]'>{t('trade_most_icon1')}</p>
                    </div></Fade>
                    <Fade bottom delay={700}><div className='w-[300px]'>
                        <img src={icon2} alt="" className='w-[75px] mx-auto'/>
                        <p className='text-white text-center font-light montserrat mt-[37px]'>{t('trade_most_icon2')}</p>
                    </div></Fade>
                </div>
                <div className='grid grid-cols-2 gap-[250px] mx-auto xl:mx-0'>
                    <Fade bottom delay={1000}><div className='w-[300px]'>
                        <img src={icon3} alt="" className='w-[75px] mx-auto'/>
                        <p className='text-white text-center font-light montserrat mt-[37px]'>{t('trade_most_icon3')}</p>
                    </div></Fade>
                    <Fade bottom delay={1200}><div className='w-[300px]'>
                        <img src={icon4} alt="" className='w-[75px] mx-auto'/>
                        <p className='text-white text-center font-light montserrat mt-[37px]'>{t('trade_most_icon4')}</p>
                    </div></Fade>
                </div>
            </div>
            {/* {Mobile Block} */}
            <div className='flex'>
                <div className='flex flex-col sm:hidden mx-auto pb-[110px]'>
                    <Fade bottom delay={500}><div>
                        <img src={icon1} alt="" className='w-[75px] mt-[50px] mx-auto'/>
                        <p className='text-white text-center font-light montserrat mt-[37px] text-[14px] sm:text-[16px] xl:text-[18px]'>{t('trade_most_mobile_icon1')}</p>
                    </div></Fade>
                    <Fade bottom delay={700}><div>
                        <img src={icon2} alt="" className='w-[75px] mt-[50px] mx-auto'/>
                        <p className='text-white text-center font-light montserrat mt-[37px] text-[14px] sm:text-[16px] xl:text-[18px]'>{t('trade_most_mobile_icon2')}</p>
                    </div></Fade>
                    <Fade bottom delay={900}><div>
                        <img src={icon1} alt="" className='w-[75px] mt-[50px] mx-auto'/>
                        <p className='text-white text-center font-light montserrat mt-[37px] text-[14px] sm:text-[16px] xl:text-[18px]'>{t('trade_most_mobile_icon3')}</p>
                    </div></Fade>
                    <Fade bottom delay={1100}><div>
                        <img src={icon2} alt="" className='w-[75px] mt-[50px] mx-auto'/>
                        <p className='text-white text-center font-light montserrat mt-[37px] text-[14px] sm:text-[16px] xl:text-[18px]'>{t('trade_most_mobile_icon4')}</p>
                    </div></Fade>
                </div>
            </div>
        </div>
    );
};

export default TradeMost;
