import React from 'react';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { slide as Menu } from 'react-burger-menu'

import Logo from '../assets/img/logo.svg'

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import i18n from "i18next";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";


const Header = () => {

  const location = useLocation();

  const languages = [
    { value: 'en', label: 'EN' },
    { value: 'fr', label: 'FR' },
    { value: 'ru', label: 'RU' },
    { value: 'pl', label: 'PL' },
  ]
  const [menuOpen, setMenuOpen] = useState(false);

  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);

  };
  useEffect(() => {
    // Добавляем/удаляем класс 'menu-open' у body при открытии/закрытии меню
    if (menuOpen) {
      document.body.classList.add('menu-open');
      document.body.classList.remove('menu-closed');
    } else {
      document.body.classList.remove('menu-open');
      document.body.classList.add('menu-closed');
    }

    return () => {
      // Удаляем класс при размонтировании компонента
      document.body.classList.remove('menu-open');
      document.body.classList.add('menu-closed');
    };
  }, [menuOpen]);
  const { t } = useTranslation();
  const storedLang = Cookies.get("i18next");
  const [currentLang, setCurrentLang] = useState(storedLang || 'en');

  useEffect(() => {
    if (storedLang && storedLang !== currentLang) {
      setCurrentLang(storedLang);
      i18n.changeLanguage(storedLang);
    }
  }, [storedLang]);

  const handleChangeLang = (selectedLang) => {
    setCurrentLang(selectedLang.value);
    i18n.changeLanguage(selectedLang.value);
    Cookies.set("i18next", selectedLang.value);
  };
  return (
    <>
    <div className='bg-black w-full h-[60px] md:h-[80px] z-50 hidden 2xl:block fixed top-0 left-0 right-0'>
      <div className='flex'>
        <Link to="/"><img src={Logo} className='h-[50px] ml-[100px] mt-[15px]' alt="" /></Link>
        <div className='flex mt-[28px] gap-[50px] mx-auto'>
          <Link to="/aboutus" className={`text-[14px] open-sans font-medium text-white ${location.pathname === '/aboutus' ? 'activee' : ''}`}>{t('1_about_us')}</Link>
          <Link to="/termsandservices" className={`text-[14px] open-sans font-medium text-white ${location.pathname === '/termsandservices' ? 'activee' : ''}`}>{t('2_terms_and_services')}</Link>
          <Link to="/accounts" className={`text-[14px] open-sans font-medium text-white ${location.pathname === '/accounts' ? 'activee' : ''}`}>{t('3_accounts')}</Link>   
          <Link to="/trading" className={`text-[14px] open-sans font-medium text-white ${location.pathname === '/trading' ? 'activee' : ''}`}>{t('4_trading')}</Link>
          <Link to="/education" className={`text-[14px] open-sans font-medium text-white ${location.pathname === '/education' ? 'activee' : ''}`}>{t('5_education')}</Link>
          <Link to="/partner" className={`text-[14px] open-sans font-medium text-white ${location.pathname === '/partner' ? 'activee' : ''}`}>{t('6_partner')}</Link>
          <Link to="/sponsorship" className={`text-[14px] open-sans font-medium text-white ${location.pathname === '/sponsorship' ? 'activee' : ''}`}>{t('7_sponsorship')}</Link>
          <Link to="/kyc" className={`text-[14px] open-sans font-medium text-white ${location.pathname === '/kyc' ? 'activee' : ''}`}>{t('8_sponsorship')}</Link>
        </div>
        <Dropdown
          options={languages}
          placeholder="EN"
          onChange={handleChangeLang}
          value={languages.find(lang => lang.value === currentLang)}
          className='mt-[20px] mr-[30px] open-sans font-medium'
        />
        <button className='w-[81px] h-[42px] bg-[#FFF62B] mt-[18px] mr-[100px] rounded-lg'> 
        <a href="https://user.amberinvest.tech"><p className='text-[16px] open-sans font-light text-black'>{t('main_login')}</p></a>
        </button>
      </div>
    </div>
      <div className='flex bg-black pr-[50px] pt-[15px] sm:pt-[25px] justify-between h-[60px] w-full fixed sm:h-[80px] z-50 2xl:hidden'>
        <Link onClick={closeMenu} to="/"><img src={Logo} className='h-[35px] ml-[20px]' alt="" /></Link>
        <Menu right isOpen={menuOpen} onStateChange={handleStateChange}>
            <Link onClick={closeMenu} to="/aboutus" className={`text-[20px] open-sans font-light text-white mt-[10px] ${location.pathname === '/aboutus' ? 'activee' : ''}`}>{t('1_about_us')}</Link>
          <Link onClick={closeMenu} to="/termsandservices" className={`text-[20px] open-sans font-light text-white mt-[10px] ${location.pathname === '/termsandservices' ? 'activee' : ''}`}>{t('2_terms_and_services')}</Link>
          <Link onClick={closeMenu} to="/accounts" className={`text-[20px] open-sans font-light text-white mt-[10px] ${location.pathname === '/accounts' ? 'activee' : ''}`}>{t('3_accounts')}</Link>   
          <Link onClick={closeMenu} to="/trading" className={`text-[20px] open-sans font-light text-white mt-[10px] ${location.pathname === '/trading' ? 'activee' : ''}`}>{t('4_trading')}</Link>
          <Link onClick={closeMenu} to="/education" className={`text-[20px] open-sans font-light text-white mt-[10px] ${location.pathname === '/education' ? 'activee' : ''}`}>{t('5_education')}</Link>
          <Link onClick={closeMenu} to="/partner" className={`text-[20px] open-sans font-light text-white mt-[10px] ${location.pathname === '/partner' ? 'activee' : ''}`}>{t('6_partner')}</Link>
          <Link onClick={closeMenu} to="/sponsorship" className={`text-[20px] open-sans font-light text-white mt-[10px] ${location.pathname === '/sponsorship' ? 'activee' : ''}`}>{t('7_sponsorship')}</Link>
          <Link onClick={closeMenu} to="/kyc" className={`text-[20px] open-sans font-light text-white mt-[10px] ${location.pathname === '/kyc' ? 'activee' : ''}`}>{t('8_sponsorship')}</Link>
            <Dropdown
                options={languages}
                placeholder="EN"
                onChange={handleChangeLang}
                value={languages.find(lang => lang.value === currentLang)}
                className='mt-[10px] ml-[-10px] open-sans font-light'
              />
            <button className='w-[81px] h-[42px] bg-[#FFF62B] mt-[10px] rounded-lg'> 
            <a href="https://user.amberinvest.tech"><p className='text-[16px] open-sans font-light text-black'>{t('main_login')}</p></a>
            </button> 
        </Menu>        
      </div>
      <div className='pb-[60px] md:pb-0'></div>


    </>
   
  );
};

export default Header;