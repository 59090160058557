import React, { useEffect } from 'react';

import Main from '../components/Home/Main'
import ProfitEdje from '../components/Home/ProfitEdje'
import TradeTrust from '../components/Home/TradeTrust'
import TradeMost from '../components/Home/TradeMost'
import Choose from '../components/Home/Choose'
import NoPrevious from '../components/Home/NoPrevious'
import InviteEarn from '../components/Home/InviteEarn'
import HowTo from '../components/Home/HowTo'


import LazyLoad from 'react-lazy-load';



const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='bg-black z-0 relative'>
      <Main />
      <LazyLoad><ProfitEdje /></LazyLoad>
      <LazyLoad><TradeTrust /></LazyLoad> 
      <LazyLoad><TradeMost/></LazyLoad>
      <LazyLoad><Choose/></LazyLoad>
      <LazyLoad><NoPrevious/></LazyLoad>
      <LazyLoad><InviteEarn/></LazyLoad>
      <LazyLoad><HowTo /></LazyLoad>
    </div>
  );
};

export default Home;