import React from 'react';
import Fade from 'react-reveal/Fade';
import img from "../../assets/img/img(1).png"
import img1 from '../../assets/img/about/1.png'
import img2 from '../../assets/img/about/chart.png'
import iphone from '../../assets/img/about/iphone.png'
import { useTranslation } from 'react-i18next';

const Info = () => {
  const { t } = useTranslation();

  return (
    <div className='bg-black mt-[-100px] sm:pt-[140px] max-w-[1280px] mx-auto'>
        <img src={img} alt="" className="imgggg animatedimgdd"/>
        <div className='max-w-[1280px] hidden xl:block'>
            <Fade bottom delay={300}><h1 className='open-sans text-[20px] sm:text-[28px] xl:text-[35px] text-white font-extrabold text-center'>{t('what_we_do.title')}</h1></Fade>
            <div className='relative z-10 flex flex-col justify-center mt-[50px] xl:mt-[80px]'>
                <div className='flex gap-[55px] mx-auto'>
                    <div className='w-[400px] h-[370px] z-0 relative bg-[#1A1919] flex justify-center'>
                        <img src={img1} alt="" className='absolute top-0 z-50'/>
                        <div>
                            <h1 className=' mt-[40px] inter text-[20px] font-normal text-[#D8D8D8]'>{t('what_we_do.financial_wellbeing.title')}</h1>
                            <p className='text-white mt-[60px] w-[320px] inter text-[16px] font-light'>{t('what_we_do.financial_wellbeing.description')}</p>
                        </div>
                    </div>
                    <div className='w-[400px] h-[370px] z-0 relative bg-[#9F44BB] flex justify-center'>
                        <div>
                            <h1 className='text-white mt-[40px] inter text-[20px] font-normal'>{t('what_we_do.trading_access.title')}</h1>
                            <p className='text-white mt-[35px] w-[320px] inter text-[16px] font-light'>{t('what_we_do.trading_access.description')}</p>
                        </div>
                    </div>
                    <div className='w-[400px] h-[707px] z-0 relative bg-[#1A1919] flex justify-center overflow-hidden'>
                        <div>
                            <h1 className='text-white mt-[40px] inter text-[20px] font-normal mx-[40px]'>{t('what_we_do.acting_as_fiduciary.title')}</h1>
                            <p className='text-white mt-[25px] w-[320px] inter text-[16px] font-light mx-[40px]'>{t('what_we_do.acting_as_fiduciary.description')}</p>
                            <img src={img2} alt="" className='absolute ml-[30px]'/>
                        </div>
                    </div>
                </div>
                <div className='w-[855px] h-[292px] bg-[#414141] absolute left-0 bottom-[1px]'>
                    <div className='flex flex-row'>
                        <div className='ml-[25px]'>
                            <h1 className='text-white mt-[40px] inter text-[20px] font-normal'>{t('what_we_do.resilient_economies.title')}</h1>
                            <p className='text-white mt-[25px] w-[420px] inter text-[16px] font-light'>{t('what_we_do.resilient_economies.description')}</p>
                        </div>
                        <div className='ml-[90px]'>
                            <img src={iphone} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='xl:hidden w-full bg-[#414141] h-auto z-10 relative flex flex-col items-center'>
            <div className='mx-[20px]'>
                <div>
                    <h1 className='pt-[40px] text-white mt-[40px] inter text-[14px] sm:text-[16px] font-normal'>{t('what_we_do.financial_wellbeing.title')}</h1>
                    <p className='text-white mt-[15px] w-[320px] inter text-[14px] sm:text-[16px] font-light'>{t('what_we_do.financial_wellbeing.description')}</p>
                    <h1 className='pt-[30px] text-white mt-[40px] inter text-[14px] sm:text-[16px] font-normal'>{t('what_we_do.trading_access.title')}</h1>
                    <p className='text-white mt-[15px] w-[320px] inter text-[14px] sm:text-[16px] font-light'>{t('what_we_do.trading_access.description')}</p>
                    <h1 className='pt-[30px] text-white mt-[40px] inter text-[14px] sm:text-[16px] font-normal'>{t('what_we_do.resilient_economies.title')}</h1>
                    <p className='text-white mt-[15px] w-[320px] inter text-[14px] sm:text-[16px] font-light'>{t('what_we_do.resilient_economies.description')}</p>
                    <h1 className='pt-[30px] text-white mt-[40px] inter text-[14px] sm:text-[16px] font-normal'>{t('what_we_do.acting_as_fiduciary.title')}</h1>
                    <p className='text-white mt-[15px] w-[320px] inter text-[14px] sm:text-[16px] font-light'>{t('what_we_do.acting_as_fiduciary.description')}</p>
                    <img src={iphone} alt="" className='mx-auto pt-[50px]'/>
                </div>
            </div>
        </div>
        <div>
            <Fade bottom delay={300}><h1 className='mt-[170px] open-sans text-[20px] sm:text-[28px] xl:text-[35px] text-white font-extrabold text-center'>{t('what_we_do.title')}</h1></Fade>
            <div className='flex flex-col xl:flex-row gap-[50px] xl:gap-[200px] justify-center mt-[75px] mx-[50px]'>
                <div>
                    <h1 className='text-white text-center text-[35px] montserrat font-bold'>{t('statistics.traders_count.number')}</h1>
                    <p className='text-white text-center mt-[5px] montserrat font-light'>{t('statistics.traders_count.description')}</p>
                </div>
                <div>
                    <h1 className='text-white text-center text-[35px] montserrat font-bold'>{t('statistics.countries_count.number')}</h1>
                    <p className='text-white text-center mt-[5px] montserrat font-light'>{t('statistics.countries_count.description')}</p>
                </div>
                <div>
                    <h1 className='text-white text-center text-[35px] montserrat font-bold'>{t('statistics.wealth_created.number')}</h1>
                    <p className='text-white text-center mt-[5px] montserrat font-light'>{t('statistics.wealth_created.description')}</p>
                </div>
            </div>
        </div>
        <div className='hidden xl:block'>
            <Fade bottom delay={300}><h1 className='mt-[170px] open-sans text-[20px] sm:text-[28px] xl:text-[35px] text-[#9F44BB] font-extrabold text-center'>Amber Invest:</h1></Fade>
            <Fade bottom delay={300}><h1 className='mt-[14px] open-sans text-[20px] sm:text-[28px] xl:text-[35px] text-white font-extrabold text-center'>{t('amber_invest.title')}</h1></Fade>
            <div className='flex justify-center mt-[75px]'>
                <div className='rounded-l-full bg-[#191919] w-[627px] h-[347px] transform'>
                    <div className='mt-[40px] ml-[291px]'>
                        <h1 className='ml-[172px] text-[#D8D8D8] open-sans text-[50px] font-extrabold'>{t('amber_invest.vision.title')}</h1>
                        <h1 className='mt-[-50px] text-[#9F44BB] open-sans text-[90px] font-extrabold'>{t('amber_invest.vision.description')}</h1>
                        <p className='text-right text-[#D8D8D8] font-light mr-[82px] mt-[35px]'>{t('amber_invest.vision.text')}</p>
                    </div> 
                </div>
                <div className='rounded-r-full bg-black w-[627px] h-[347px] transform'> 
                    <div className='mt-[40px] ml-[65px]'>
                        <h1 className='text-[#D8D8D8] open-sans text-[50px] font-extrabold'>{t('amber_invest.mission.title')}</h1>
                        <h1 className='mt-[-50px] text-[#9F44BB] open-sans text-[90px] font-extrabold'>{t('amber_invest.mission.description')}</h1>
                        <div className='flex'>
                            <p className='text-left text-[#D8D8D8] font-light mr-[82px] mt-[35px]'>{t('amber_invest.mission.text')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='block xl:hidden flex-col mt-[110px] pb-[100px]'>
            <div className='rounded-full h-[122px] w-[339px] bg-[#9F44BB] mx-auto items-center flex flex-col'>
                <h1 className='open-sans text-[18px] text-white font-extrabold text-center mt-[22px]'>{t('amber_invest.vision.title')}{t('amber_invest.vision.description')}</h1>
                <p className='text-white text-center text-[14px] font-light mt-[10px] max-w-[200px]'>{t('amber_invest.vision.text')}</p>
            </div>
            <h1 className='mt-[50px] text-center ml-[200px] text-[#D8D8D8] open-sans text-[30px] font-extrabold'>{t('amber_invest.mission.title')}</h1>
            <h1 className='mt-[-45px] text-[#9F44BB] open-sans text-[70px] font-extrabold text-center'>{t('amber_invest.mission.description')}</h1>
            <p className='text-center text-[14px] text-[#D8D8D8] font-light mt-[8px] w-[250px] xl:w-full mx-auto xl:mx-0'>{t('amber_invest.mission.text')}</p>
        </div>
    </div>
  );
};

export default Info;
