import React from 'react';
import Fade from 'react-reveal/Fade';
import { useTranslation } from "react-i18next";
import Ticker from '../Home/Ticker';

const HowTo = () => {
    const { t } = useTranslation();

    return (
        <div className='bg-black pt-[111px] sm:pt-[140px] xl:pt-[190px] pb-[110px]'>
            <Fade bottom delay={300}><h1 className='open-sans text-[20px] sm:text-[28px] xl:text-[35px] text-white font-extrabold text-center'>{t('1_deposit_withdraw_title')}</h1>
            <p className='montserrat text-[14px] sm:text-[16px] xl:text-[18px] text-center mt-[30px] font-light text-white mx-5'>{t('2_deposit_withdraw_description')}</p>
            </Fade>
            <Ticker/>
        </div>
    );
};

export default HowTo;
