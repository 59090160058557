import React from "react";

import { Trans, useTranslation } from 'react-i18next';

import { useState, useRef } from 'react';

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper/modules';

import 'swiper/css';

import Vector from '../../assets/img/button.svg'


const Explore = () => {
    const [activeDiv, setActiveDiv] = useState(1);
    const { t } = useTranslation();

    const swiperRef = useRef(null);

    const handleButtonClick = (divNumber) => {
      setActiveDiv(divNumber);
    };
    const plans = [
        {
            name: "BRONZE",
            price: "$250",
            features: [
                `${t("swiper.1")}(Trial)`,
                t("swiper.2"),
                t("swiper.3"),
                t("swiper.4"),
                <strike>{t("swiper.5")}</strike>,
                <strike>{t("swiper.6")}</strike>,
                <strike>{t("swiper.7")}</strike>,
                <strike>{t("swiper.8")}</strike>,
                <strike>{t("swiper.9")}</strike>,
                <strike>{t("swiper.10")}</strike>
            ],
            url: "https://user.amberinvest.tech/login"
        },
        {
            name: "SILVER",
            price: "$2.500",
            features: [
                t("swiper.1"),
                t("swiper.2"),
                t("swiper.3"),
                t("swiper.4"),
                t("swiper.5"),
                <strike>{t("swiper.6")}</strike>,
                <strike>{t("swiper.7")}</strike>,
                <strike>{t("swiper.8")}</strike>,
                <strike>{t("swiper.9")}</strike>,
                <strike>{t("swiper.10")}</strike>
            ],
            url: "https://user.amberinvest.tech/login"
        },
        {
            name: "GOLD",
            price: "$10.000",
            features: [
                t("swiper.1"),
                t("swiper.2"),
                t("swiper.3"),
                t("swiper.4"),
                t("swiper.5"),
                t("swiper.6"),
                t("swiper.7"),
                t("swiper.8"),
                <strike>{t("swiper.9")}</strike>,
                <strike>{t("swiper.10")}</strike>
            ],
            url: "https://user.amberinvest.tech/login"
        },
        {
            name: "PLATINUM",
            price: "$50.000",
            features: [
                t("swiper.1"),
                t("swiper.2"),
                t("swiper.3"),
                t("swiper.4"),
                t("swiper.5"),
                t("swiper.6"),
                t("swiper.7"),
                t("swiper.8"),
                t("swiper.9"),
                <strike>{t("swiper.10")}</strike>
            ],
            url: "https://user.amberinvest.tech/login"
        },
        {
            name: "PREMIUM",
            price: "$250.000",
            features: [
                t("swiper.1"),
                t("swiper.2"),
                t("swiper.3"),
                t("swiper.4"),
                t("swiper.5"),
                t("swiper.6"),
                t("swiper.7"),
                t("swiper.8"),
                t("swiper.9"),
                t("swiper.10")
            ],
            url: "https://user.amberinvest.tech/login"
        },
    ];
    const profplans = [
        {
            name: "VIP",
            price: "$500000+",
            features: [
                "proswiper.1",
                "proswiper.2"
            ]
        },
        {
            name: "VIP +",
            price: "$1000000+",
            features: [
                "proswiper.3",
                "proswiper.4"
            ]
        },

    ];
    const indplans = [
        {
            name: "indswiper.1",
            features: [
                "indswiper.2",
            ],
            featurest: [
                "indswiper.3",
                "indswiper.4",
                "indswiper.5",
                "indswiper.6",
                "indswiper.7",
                "indswiper.8",
                "indswiper.9",
            ]
        },
        {
            name: "indswiper.10",
            features: [
                "indswiper.11",
                "indswiper.12",
                "indswiper.13",
                "indswiper.14",
            ],
            featurest: [
                "indswiper.15",
                "indswiper.16",
                "indswiper.17",
                "indswiper.18",
                "indswiper.19",
                "indswiper.20",
                "indswiper.21",
            ]
        },
        {
            name: "indswiper.22",
            features: [
                "indswiper.23",
                "indswiper.24",
                "indswiper.25",
            ],
            featurest: [
                "indswiper.26",
                "indswiper.27",
                "indswiper.28",
                "indswiper.29",
                "indswiper.30",
            ]
        },
        {
            name: "indswiper.31",
            features: [
                "indswiper.32",
                "indswiper.33",
                "indswiper.34",,
            ],
            featurest: [
                "indswiper.35",
                "indswiper.36",
                "indswiper.37",
                "indswiper.38",
                "indswiper.39",
                "indswiper.40",
                "indswiper.41",
                "indswiper.42",
            ]
        },
        {
            name: "indswiper.43",
            features: [
                "indswiper.44",
                "indswiper.45",
                "indswiper.46",,
            ],
            featurest: [
                ""
            ]
        }

    ];
    const handleDivClick = (url) => {
        window.open(url, "_blank");
    };
    const Div1 = () => (
        <div className="flex flex-col xl:flex-row gap-[30px] justify-center items-center mx-[20px]">
        {plans.map((plan, index) => (
            <div key={index} className="w-full xl:w-[220px] xl:h-[727px] bg-[#414040] flex flex-col justify-center items-center">
                <h1 className="open-sans text-white text-[17px] font-extrabold mt-[10px] xl:mt-0">{plan.name}</h1>
                <h1 className="open-sans text-[#E07DFF] font-extrabold text-[30px]">{plan.price}</h1>
                <p className="text-[14px] font-light text-white open-sans text-center">{t("swiper.11")}</p>
                {plan.features.map((feature, fIndex) => (
                    <React.Fragment key={fIndex}>
                        <p className="text-[14px] font-light text-white open-sans mt-[20px] text-center">{feature}</p>
                        {fIndex !== plan.features.length - 1 && <div className="h-[1px] w-full xl:w-[210px] bg-white opacity-50 mt-[8px]"></div>}
                    </React.Fragment>
                ))}
                <div className="mt-[30px] bopen cursor-pointer mb-[10px] xl:mb-0 rounded-[10px] hover:bg-[#FFF62B] transition-all duration-300" onClick={() => handleDivClick(plan.url)}>
                    <p className="plus-jakarta font-medium text-[14px]">{t("buttonswitcher")}</p>
                </div>
            </div>
        ))}
    </div>
      );
      const Div2 = () => (
        <div className="flex flex-col xl:flex-row gap-[50px] justify-center items-center">
        {profplans.map((plan, index) => (
            <div key={index} className="w-[358px] h-[497px] bg-[#414040] flex flex-col justify-center items-center">
                <h1 className="open-sans text-white text-[17px] font-extrabold mt-[10px] xl:mt-0">{plan.name}</h1>
                <h1 className="open-sans text-[#E07DFF] font-extrabold text-[30px]">{plan.price}</h1>
                <p className="text-[14px] font-light text-white open-sans text-center">{t("proswiper.5")}</p>
                <p className="text-[14px] font-light text-white open-sans text-center mt-[8px]">{t("proswiper.6")}</p>
                {plan.features.map((feature, fIndex) => (
                    <React.Fragment key={fIndex}>
                        <p className="text-[14px] font-light text-white open-sans mt-[20px] mx-[25px]">{t(feature)}</p>
                    </React.Fragment>
                ))}
                <div className="mt-[30px] bopen cursor-pointer mb-[10px] xl:mb-0 rounded-[10px] hover:bg-[#FFF62B] transition-all duration-300" onClick={() => handleDivClick(plan.url)}>
                    <p className="plus-jakarta font-medium text-[14px]">{t("buttonswitcher")}</p>
                </div>
            </div>
         ))}
        </div>
      );
      const Div3 = () => (
        <div className="mx-[20px] xl:mx-0">
            <Swiper
                modules={[Navigation]}
                slidesPerView={1}
                className='mt-[40px]'
                loop={true}
                onSwiper={(swiper) => swiperRef.current = swiper}
                breakpoints={{
                    320: {
                        slidesPerView: 1,
                        },
                    768: {
                        slidesPerView: 2,
                        },
                    1280: {
                        slidesPerView: 3,
                        },
                    }}
            >
             {indplans.map((plan, index) => (
                <SwiperSlide>
                    <div key={index} className="flex mx-auto flex-col justify-between xl:w-[358px] h-[598px] bg-[#414040]">
                        <div>
                            <h1 className="open-sans text-[#E07DFF] text-[17px] font-extrabold pt-[35px] text-center xl:mt-0 mb-[20px]">{t(plan.name)}</h1>
                            {plan.features.map((feature, fIndex) => (
                                <p key={fIndex} className="text-[14px] font-light text-white open-sans mx-[20px]">{t(feature)}</p>
                            ))}
                            <div className="mt-[15px]"></div>
                            {plan.featurest.map((feature, fIndex) => (
                                <p key={fIndex} className="text-[14px] mx-[20px] text-left font-light text-white open-sans"><Trans>{t(feature)}</Trans></p>
                            ))}
                        </div>
                        <div className="max-w-[140px] mx-auto bopen cursor-pointer mb-[20px] rounded-[10px] hover:bg-[#FFF62B] transition-all duration-300" onClick={() => handleDivClick(plan.url)}>
                            <p className="plus-jakarta font-medium text-[14px]">{t("buttonswitcher")}</p>
                        </div>
                    </div>
                </SwiperSlide>
            ))}
            </Swiper>
        </div>
      );
    

    return (
        <div className="bg-black pt-[50px] xl:pt-[180px] ">
            <div className="max-w-[1280px] mx-auto flex flex-col">
                <div className="flex items-center justify-center flex-col xl:flex-row">
                    <div className={`buttonaccount scale-90 xl:scale-100 ${activeDiv === 1 ? 'buttonswitcheractive' : 'buttonswitcherdisactive'}`} onClick={() => handleButtonClick(1)}>
                        <p className="text-white open-sans text-[16px] xl:text-[17px] font-medium">Standard</p>
                    </div>
                    <div className={`buttonaccount scale-90 xl:scale-100 ${activeDiv === 2 ? 'buttonswitcheractive' : 'buttonswitcherdisactive'}`} onClick={() => handleButtonClick(2)}>
                        <p className="text-white open-sans text-[16px] xl:text-[17px] font-medium">Professional</p>
                    </div>
                    <div className={`buttonaccount scale-90 xl:scale-100 ${activeDiv === 3 ? 'buttonswitcheractive' : 'buttonswitcherdisactive'}`} onClick={() => handleButtonClick(3 )}>
                        <p className="text-white open-sans text-[16px] xl:text-[17px] font-medium">Individual</p>
                    </div>
                </div>
                {activeDiv === 3 &&<div className='mx-auto xl:mx-0 xl:ml-auto mt-[15px] xl:mt-0 mb-[-20px] xl:mb-[-83px] flex gap-[20px]'>
                        <div
                        onClick={() => swiperRef.current.slidePrev()}
                        className='rotate-180'
                        >
                            <img src={Vector} alt="" className="rotate-180"/>
                        </div>
                        
                        <div
                        onClick={() => swiperRef.current.slideNext()}
                        className='w-[43px] h-[43px]'
                        >
                            <img src={Vector} alt="" className="rotate-180"/>
                        </div>
                    </div>}
                <div className="mt-0 xl:mt-[75px]">
                    {activeDiv === 1 && <Div1 />}
                    {activeDiv === 2 && <Div2 />}
                    {activeDiv === 3 && <Div3 />}
                </div>
                <div className="flex flex-col xl:flex-row pt-[50px] xl:pt-[180px] justify-between">
                    <div className="max-w-[525px]">
                        <p className="text-[#E07DFF] font-extrabold text-[17px] open-sans">{t("join.1")}</p>
                        <p className="max-w-[525px] open-sans text-white font-light text-[14px] mt-[15px]">{t("join.2")}</p>
                        <p className="text-[17px] open-sans text-white mt-[35px]"><Trans>{t("join.3")}</Trans></p>
                        <p className="max-w-[455px] mt-[15px] text-[14px] font-light open-sans text-white"> {t("join.4")}</p>
                        <p className="max-w-[455px] mt-[15px] text-[14px] font-light open-sans text-white"> {t("join.5")}</p>
                    </div>
                    <div className="max-w-[525px]">
                        <p className="text-[#E07DFF] font-extrabold text-[17px] open-sans">{t("join.6")}</p>
                        <p className="max-w-[525px] open-sans text-white font-light text-[14px] mt-[15px]">{t("join.7")}</p>
                        <p className="text-[17px] open-sans text-white mt-[35px]"><Trans>{t("join.8")}</Trans></p>
                        <p className="max-w-[455px] mt-[15px] text-[14px] font-light open-sans text-white">{t("join.9")}</p>
                        <p className="max-w-[455px] mt-[15px] text-[14px] font-light open-sans text-white"> {t("join.10")}</p>
                        <p className="max-w-[455px] mt-[15px] text-[14px] font-light open-sans text-white"> {t("join.11")}</p>
                    </div>
                </div>
            </div>
        </div>
      );
};

    
    
export default Explore;
