import React from "react";
import img from "../../assets/img/img(1).png";
import Fade from 'react-reveal/Fade';
import { useTranslation } from "react-i18next";

const ProfitEdje = () => {
    const { t } = useTranslation();

    return (
        <div className="bg-black z-50 relative">
            <Fade duration={2000} bottom><img src={img} alt="" className="imgg"/></Fade>
            <Fade duration={2000} delay={500} bottom><div className="w-[360px] sm:w-[600px] mx-auto text-center pt-0 sm:pt-[100px] xl:pb-[190px] pb-[110px] z-10 relative">
                <p className="text-white montserrat text-[14px] sm:text-[16px] xl:text-[18px] font-light">{t('1_platform_description')}</p>
                <button className='w-[161px] h-[47px] bg-[#FFF62B] mt-[50px] rounded-lg mx-auto'> 
                  <a href="https://user.amberinvest.tech"><p className='text-[18px] font-medium open-sans text-black'>{t('4_start_trading')}</p></a>
                </button>
            </div></Fade>    
        </div>
      );
};

export default ProfitEdje;
