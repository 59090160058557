import React, { useEffect } from 'react';

import Main from '../components/AboutUs/Main'
import Info from '../components/AboutUs/Info'
import Info2 from '../components/AboutUs/Info2'

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='bg-black z-0 relative'>
      <Main />
      <Info />
      <Info2 />
    </div>
  );
};

export default AboutUs;