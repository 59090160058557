import {  useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

import phoro from '../assets/img/partnerwithus/Image.jpg'


import Fade from 'react-reveal/Fade';

import Vector from '../assets/img/Vector.png'
import p01 from '../assets/img/01.png'

const Sponsorship = () => {
  const { t } = useTranslation();
  const [traders, setTraders] = useState(1);
  const [lots, setLots] = useState(1);

  const handleTradersChange = (e) => {
    setTraders(e.target.value);
  };

  const handleLotsChange = (e) => {
    setLots(e.target.value);
  };

  const calculateReward = () => {
    // Это место для вашей логики расчета вознаграждения
    return (traders * lots * 5.92).toFixed(2);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='bg-black z-10 relative xl:mt-[80px] overflow-hidden'>
        <div className='h-[455px] bg-white hidden xl:flex overflow-hidden relative'>
          <div className='w-1/2 h-full bgbgbgbgfd relative z-10 flex flex-col justify-center items-end pr-[170px]'>
            <div>
              <div className='flex'>
                <div className='mt-[8px] w-[11px] h-[11px] bg-[#82279E]'></div>
                <p className='ml-[7px] text-white font-bold text-[18px] inter'>{t("sponsorship.section14")}</p>
              </div>
              <h1 className='plus-jakarta text-[45px] text-white font-extrabold'>{t("sponsorship.section2")}<br/>{t("sponsorship.section9")}</h1>
              <p className='text-white inter text-[18px] max-w-[523px] font-light'>{t("partner.section6")}</p>
            </div>
          </div>
          <img src={phoro} alt="" className='absolute right-0 h-full w-auto'/>
        </div>
        <div className='flex flex-col xl:hidden'>
          <img src={phoro} alt="" className='object-cover'/>
          <div className='mx-[20px] mt-[40px]'>
            <div className='flex'>
              <div className='mt-[8px] w-[11px] h-[11px] bg-[#82279E]'></div>
              <p className='ml-[7px] text-white font-bold text-[18px] inter'>Partner</p>
            </div>
            <h1 className='plus-jakarta text-[20px] mt-[10px] text-white font-extrabold'>{t("sponsorship.section20")} </h1>
          </div>
        </div>
        <div className='bg-black relative z-50 max-w-[1280px] mx-auto pt-[90px] xl:pt-[170px]'>
          <div className='flex flex-col xl:flex-row mx-[20px] xl:mx-0'>
            <div className='xl:w-1/2 mx-auto'>
              <h1 className='open-sans text-[20px] font-extrabold text-white xl:text-[35px]'>{t("sponsorship.section26")}</h1>
              <p className='mt-[30px] max-w-[525px] montserrat xl:text-[18px] text-white font-light text-[14px]'>{t("sponsorship.section21")}</p>
              <h1 className='open-sans text-[14px] xl:text-[18px] font-extrabold text-white mt-[20px]'>{t("sponsorship.section13")}</h1>
              <p className='mt-[20px] max-w-[525px] montserrat xl:text-[18px] text-white font-light text-[14px]'>{t("sponsorship.section15")}</p>
              <p className='mt-[20px] max-w-[525px] montserrat xl:text-[18px] text-white font-light text-[14px]'>{t("sponsorship.section10")}</p>
              <button className='w-[220px] h-[42px] bg-[#FFF62B] mt-[50px] mr-[150px] rounded-lg'> 
              <a href="https://user.amberinvest.tech"><p className='text-[16px] open-sans font-semibold text-black'>{t("sponsorship.section23")}</p></a>
              </button>
            </div>
            <div className='xl:w-1/2 mt-[50px] xl:mt-0'>
              <h1 className='open-sans text-[20px] font-extrabold text-white xl:text-[35px]'>{t("sponsorship.section24")}</h1>
              <p className='mt-[30px] max-w-[525px] montserrat xl:text-[18px] text-white font-light text-[14px]'>{t("sponsorship.section18")}</p>
              <h1 className='open-sans text-[14px] xl:text-[18px] font-extrabold text-white mt-[20px]'>{t("sponsorship.section13")}</h1>
              <p className='mt-[20px] max-w-[525px] montserrat xl:text-[18px] text-white font-light text-[14px]'>{t("sponsorship.section25")}</p>
              <p className='mt-[20px] max-w-[525px] montserrat xl:text-[18px] text-white font-light text-[14px]'>{t("sponsorship.section22")}</p>
              <button className='w-[220px] h-[42px] bg-[#FFF62B] mt-[50px] mr-[150px] rounded-lg'> 
              <a href="https://user.amberinvest.tech"><p className='text-[16px] open-sans font-semibold text-black'>{t("sponsorship.section6")}</p></a>
              </button>
            </div>
          </div>
          <div className='pt-[50px] xl:pt-[170px] mx-[20px] xl:mx-0'>
            <div className='bg-[#414040] w-full xl:h-[463px] rounded-[20px]'>
              <div className='flex xl:flex-row flex-col pt-[50px] mx-[15px] xl:mx-[70px]'>
                <div className='max-w-[500px] mx-auto xl:mx-0'>
                  <h1 className='open-sans text-[20px] font-extrabold text-white xl:text-[30px]'>{t("sponsorship.section5")}<br/> {t("sponsorship.section8")}</h1>
                  <div className='mt-[40px]'>
                    <p className='font-light xl:text-[16px] text-[14px] montserrat text-white'>Active referral clients: {traders} traders</p>
                    <input
                      id="traders-slider"
                      type="range"
                      min="1"
                      max="100"
                      value={traders}
                      onChange={handleTradersChange}
                      className="mt-[20px] w-full h-2 rounded-[600px] cursor-pointer slider"
                    />
                  </div>
                  <div className='mt-[40px]'>
                    <p className='font-light xl:text-[16px] text-[14px] montserrat text-white'>Average trading volume per trader: {lots} lots</p>
                    <input
                      id="lots-slider"
                      type="range"
                      min="1"
                      max="100"
                      value={lots}
                      onChange={handleLotsChange}
                      className="mt-[20px] w-full h-2 rounded-[600px] cursor-pointer slider"
                    />
                  </div>
                  <p className='mt-[40px] max-w-[525px] font-light text-white text-[12px]'>{t("sponsorship.section12")}</p>
                </div>
                <div className='xl:ml-auto mx-auto xl:mx-0 pt-[40px] xl:pt-0 pb-[20px] xl:pb-0'>
                  <div className='w-[300px] xl:w-[525px] h-[140px] rounded-[5px] bg-[#9211C9]'>
                    <p className='font-light xl:text-[16px] text-[14px] montserrat text-white text-center pt-[30px]'>{t("sponsorship.section1")}</p>
                    <p className='mx-[20px] xl:mx-0 font-light xl:text-[25px] text-[18px] montserrat text-white text-center pt-[15px]'>{lots} lot and {traders} trader = {calculateReward()} USD</p>
                  </div>
                  <h1 className='font-light xl:text-[18px] text-[14px] montserrat text-white text-center pt-[40px]'>{t("sponsorship.section17")}</h1>
                  <div className='flex justify-center'>
                    <button className='w-[173px] h-[42px] bg-[#FFF62B] mt-[40px] rounded-lg'> 
                    <a href="https://user.amberinvest.tech"><p className='text-[16px] open-sans font-semibold text-black'>{t("sponsorship.section4")}</p></a>
                    </button>
                  </div>
                </div>
              </div> 
            </div>
          </div>
          <div className='bg-black pt-[110px] xl:pt-[170px] pb-[431px] hidden 2xl:block'>
            <Fade bottom delay={300}><h1 className='open-sans text-[20px] sm:text-[28px] xl:text-[35px] text-white font-extrabold text-center w-[340px] md:w-[662px] xl:w-full'>{t("sponsorship.section28")}</h1>
            </Fade>
            <div className='flex justify-center'>
                <Fade bottom delay={300}><img src={Vector} className='imgg' alt="" /></Fade>
                <img src={p01} className='imggg' alt="" />
            </div>
            <div className='flex flex-col container mx-auto'>
                <div className='flex z-10 mx-auto'>
                    <Fade>
                    <div className='w-[510px] h-[224px] bg-prev mt-[115px] mr-[]'>
                        <h1 className='montserrat text-[23px] text-white font-bold mt-[50px] ml-[50px]'>{t("sponsorship.section19")}</h1>
                        <p className='montserrat text-[18px] mt-[15px] font-light text-white ml-[50px]'>{t("sponsorship.section7")}</p>
                    </div>
                    </Fade>
                    <Fade delay={1000}>
                    <div className='w-[510px] h-[224px] bg-prev mt-[323px] ml-[200px]'>
                        <h1 className='montserrat text-[23px] text-white font-bold mt-[50px] ml-[50px]'>{t("sponsorship.section11")}</h1>
                        <p className='montserrat text-[18px] mt-[15px] font-light text-white ml-[50px] mr-[103px]'>{t("sponsorship.section27")}</p>
                    </div>
                    </Fade>
                </div>
                <Fade delay={2000}>
                <div className='w-[510px] h-[224px] bg-prev absolute mt-[526px] ml-[30px]'>
                        <h1 className='montserrat text-[23px] text-white font-bold mt-[50px] ml-[50px]'>{t("sponsorship.section3")}</h1>
                        <p className='montserrat text-[18px] mt-[15px] font-light text-white ml-[50px] mr-[103px]'>{t("sponsorship.section16")}</p>
                </div>
                </Fade>           
            </div>
          </div>
          <div className='pt-[110px] bg-black block 2xl:hidden pb-[110px] sm:pb-[140px]'>
              <Fade bottom delay={300}><h1 className='open-sans text-[20px] sm:text-[28px] xl:text-[35px] w-[340px] md:w-[662px] mx-auto text-white font-extrabold text-center'>{t("sponsorship.section28")}</h1>
                                    </Fade>
              <Fade bottom delay={600}> <div className='mt-[50px] sm:mt-[65px] ml-[10%]'>
                  <h1 className='montserrat text-[16px] text-white font-extrabold'>{t("sponsorship.section19")}</h1>
                  <p className='montserrat text-[14px] sm:text-[16px] xl:text-[18px] mt-[15px] font-light text-white'>{t("sponsorship.section7")}</p>
              </div></Fade>
              <Fade bottom delay={900}><div className='mt-[30px] ml-[10%]'>
                  <h1 className='montserrat text-[16px] text-white font-extrabold'>{t("sponsorship.section11")}</h1>
                  <p className='montserrat text-[14px] sm:text-[16px] xl:text-[18px] mt-[15px] font-light text-white'>{t("sponsorship.section27")}</p>
              </div></Fade>
              <Fade bottom delay={1200}><div className='mt-[30px] ml-[10%]'>
                  <h1 className='montserrat text-[16px] text-white font-extrabold'>{t("sponsorship.section3")}</h1>
                  <p className='montserrat text-[14px] sm:text-[16px] xl:text-[18px] mt-[15px] font-light text-white'>{t("sponsorship.section16")}</p>
              </div></Fade>            
          </div>
        </div>
    </div>
  );
};

export default Sponsorship;