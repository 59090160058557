import React from 'react';
import Fade from 'react-reveal/Fade';
import { useTranslation } from "react-i18next";
import img from '../../assets/img/main.jpg';

const InviteEarn = () => {
    const { t } = useTranslation();

    return (
        <div className='bg-black h-[436px]'>
            <img src={img} className='h-[436px] w-full z-0 absolute mainbg' alt="" />
            <div className='pt-[65px] relative z-10'>  
                <div className='bg-main w-[339px] sm:w-[550px] xl:w-[662px] h-[299px] flex flex-col mx-auto'>
                    <Fade duration={1000}top><h1 className='open-sans text-[20px] sm:text-[28px] xl:text-[35px] text-white font-extrabold mx-auto mt-[16px]'>{t('1_invite_earn_title')}</h1></Fade>
                    <Fade duration={2000}top><p className='open-sans text-[14px] sm:text-[16px] xl:text-[20px] font-light text-center text-white mx-auto mt-[20px]'>{t('2_invite_earn_description_1')}</p></Fade>
                    <Fade duration={3000}top><p className='open-sans text-[14px] sm:text-[16px] xl:text-[20px] font-light text-white mx-auto mt-[10px]'>{t('3_invite_earn_description_2')}</p></Fade>
                    <button className='w-[161px] h-[47px] bg-[#FFF62B] mt-[50px] rounded-lg mx-auto'> 
                        <a href="https://user.amberinvest.tech"><p className='text-[18px] font-medium open-sans text-black'>{t('4_start_trading')}</p></a>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default InviteEarn;
