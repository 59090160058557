import React from 'react';
import logo from '../assets/img/logo.svg';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <>
            {/* Desktop */}
            <div className='w-full bg-[#9F44BB] hidden xl:block relative z-5'>
                <div className='container flex mx-auto pt-[67px]'>
                    <img src={logo} alt="" className='mb-auto' />
                    <div className='ml-[100px]'>
                        <p className='textfooter flex'>{t('footer_contacts')}<h1 className='ml-[5px] textfooter'>+6531590681</h1></p>
                        <p className='textfooter mt-[11px] flex'>{t('footer_address')}<h1 className='ml-[5px] textfooter'>1 RAFFLES LINK, #07-01, ONE RAFFLES LINK, 039393</h1></p>
                        <p className='textfooter mt-[11px] flex'>{t('footer_email')}<h1 className='ml-[5px] textfooter'>support@amber-invest.org<br/>info@amber-invest.ltd</h1></p>
                    </div>
                    <div className='w-[700px] pb-[20px] ml-[90px]'>
                        <p className='textfootertwo'>{t('footer_disclaimer')}</p>
                        <p className='textfootertwo'>{t('footer_disclaimer_text')}</p>
                        <p className='textfooterthree flex justify-end mt-[10px]'>{t('footer_all_rights_reserved')}</p>
                    </div>
                </div>
            </div>

            {/* Tablet Mobile */}
            <div className='w-full bg-[#9F44BB] h-full block xl:hidden relative z-5'>
                <div className='container flex flex-col mx-auto pt-[20px] pl-[10px]'>
                    <div className='flex'>
                        <div className=''>
                            <p className='textfooter flex'>{t('footer_contacts')}<h1 className='ml-[5px] textfooter'>+6531590681</h1></p>
                            <p className='textfooter mt-[11px] flex'>{t('footer_address')}<h1 className='ml-[5px] textfooter'>1 RAFFLES LINK, #07-01, ONE RAFFLES LINK, 039393</h1></p>
                            <p className='textfooter mt-[11px] flex'>{t('footer_email')}<h1 className='ml-[5px] textfooter'>support@amber-invest.org<br/>info@amber-invest.ltd</h1></p>
                        </div>
                    </div>
                    <div className='pt-[20px]'>
                        <p className='textfootertwo'>{t('footer_disclaimer')}</p>
                        <p className='textfootertwo text-[14px] sm:text-[16px] xl:text-[18px]'>{t('footer_disclaimer_text')}</p>
                        <p className='textfooterthree flex justify-end mt-[10px] pb-[20px]'>{t('footer_all_rights_reserved')}</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer;
