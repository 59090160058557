import React from 'react';

import Marquee from "react-fast-marquee";

import one from '../../assets/img/dash.png'
import two from '../../assets/img/bitcoin.png'
import three from '../../assets/img/litecoin.png'
import four from '../../assets/img/eth.png'
import five from '../../assets/img/primecoin.png'
import six from '../../assets/img/net.png'
import seven from '../../assets/img/mastercard.png'
import eight from '../../assets/img/webmoney.png'


const Ticker = () => {
  return (
    <div className='bg-black pt-[100px]'>
        <Marquee autoFill>
            <img src={one} alt="" className='h-[20px] sm:h-[30px] xl:h-[40px] mr-[30px] sm:mr-[35px] xl:mr-[60px]'/>
            <img src={two} alt="" className='h-[20px] sm:h-[30px] xl:h-[40px] mr-[30px] sm:mr-[35px] xl:mr-[60px]'/>
            <img src={three} alt="" className='h-[20px] sm:h-[30px] xl:h-[40px] mr-[30px] sm:mr-[35px] xl:mr-[60px]'/>
            <img src={four} alt="" className='h-[20px] sm:h-[30px] xl:h-[40px] mr-[30px] sm:mr-[35px] xl:mr-[60px]'/>
            <img src={five} alt="" className='h-[20px] sm:h-[30px] xl:h-[40px] mr-[30px] sm:mr-[35px] xl:mr-[60px]'/>
            <img src={six} alt="" className='h-[20px] sm:h-[30px] xl:h-[40px] mr-[30px] sm:mr-[35px] xl:mr-[60px]'/>
            <img src={seven} alt="" className='h-[20px] sm:h-[30px] xl:h-[40px] mr-[30px] sm:mr-[35px] xl:mr-[60px]'/>
            <img src={eight} alt="" className='h-[20px] sm:h-[30px] xl:h-[40px] mr-[30px] sm:mr-[35px] xl:mr-[60px]'/>
        </Marquee>
    </div>
  );
};

export default Ticker;