import React from 'react';

import info from '../../assets/img/tradingconditions/info.png'
import infotwo from '../../assets/img/tradingconditions/infotwo.jpg'
import imggggg from '../../assets/img/tradingconditions/imggggg.png'

import img from "../../assets/img/img(1).png"

import { useTranslation } from 'react-i18next';

import Fade from 'react-reveal/Fade';

const Info = () => {
    const { t } = useTranslation();
  return (
    <div className='bg-black mt-[70px] xl:mt-[150px]'>
        <div className='w-[339px] sm:w-[688px] xl:w-[1280px] mx-auto'>
            <img src={img} alt="" className="imgggg animatedimgd"/>
            <Fade bottom><div className='flex justify-between flex-col xl:flex-row z-10 relative'>
                <div>
                    <h1 className='open-sans text-[20px] sm:text-[25px] xl:text-[35px] text-white font-extrabold'>{t("info.section10")} </h1>
                    <p className='mt-[30px] font-light xl:w-[525px] sm:w-[688px] w-[339px] text-white text-[14px] sm:text-[16px] xl:text-[18px]'>{t("info.section13")}</p>
                    <button className='w-[161px] h-[47px] bg-[#FFF62B] mt-[50px] rounded-lg mx-auto'> 
                    <a href="https://user.amberinvest.tech"><p className='text-[18px] font-medium open-sans text-black'>{t("info.section5")}</p></a>
                    </button>
                </div>
                <img src={info} alt="" className='xl:w-[767px] xl:h-[530px] w-[373px] h-[227.744px] sm:w-[575.974px] sm:h-[398px] mx-auto mt-[50px] xl:mt-[0px]'/>
            </div></Fade>
            <img src={img} alt="" className="imgggg animatedimgd"/>
            <Fade bottom>
                <div className='flex justify-between items-center flex-col xl:flex-row z-10 relative mt-[110px] xl:mt-[180px]'>
                    <div className='xl:w-[906px] h-[190px] bg-[#414040] flex flex-col justify-center items-center xl:justify-start xl:items-start'>
                        <p className='open-sans text-white text-[16px] xl:text-[18px] font-[600] xl:mt-[30px] xl:ml-[40px]'>AnyDesk</p>
                        <p className='open-sans text-white text-[16px] xl:text-[18px] font-[300] xl:mt-[15px] xl:ml-[40px] max-w-[758px] mx-[20px] xl:mx-0'>{t("anydesk")}</p>
                    </div>
                    <a href="https://anydesk.com/"><div className='bg-[#FFF62B] flex items-center justify-center w-[141px] h-[47px] cursor-pointer mt-[10px] xl:mt-0'>
                        <p className='text-black text-[16px] xl:text-[18px] font-[500]'>{t("Download")}</p>
                    </div></a>
                </div>
            </Fade>
            <Fade bottom>
                <div className='flex justify-between items-center flex-col xl:flex-row z-10 relative mt-[50px] xl:mt-[100px]'>
                    <div className='xl:w-[906px] h-[190px] bg-[#414040] flex flex-col justify-center items-center xl:justify-start xl:items-start'>
                        <p className='open-sans text-white text-[16px] xl:text-[18px] font-[600] xl:mt-[30px] xl:ml-[40px]'>Team Viewer</p>
                        <p className='open-sans text-white text-[16px] xl:text-[18px] font-[300] xl:mt-[15px] xl:ml-[40px] max-w-[758px] mx-[20px] xl:mx-0'>{t("teamviewer")}</p>
                    </div>
                    <a href="https://www.teamviewer.com/"><div className='bg-[#FFF62B] flex items-center justify-center w-[141px] h-[47px] cursor-pointer mt-[10px] xl:mt-0'>
                        <p className='text-black text-[16px] xl:text-[18px] font-[500]'>{t("Download")}</p>
                    </div></a>
                </div>
            </Fade>
            <Fade bottom><div className='flex justify-between flex-col xl:flex-row z-10 relative mt-[110px] xl:mt-[180px]'>
                <div>
                    <h1 className='open-sans text-[20px] sm:text-[25px] xl:text-[35px] text-white font-extrabold'>{t("info.section12")}</h1>
                    <p className='mt-[30px] font-light xl:w-[525px] sm:w-[688px] w-[339px] text-white text-[14px] sm:text-[16px] xl:text-[18px]'>{t("info.section2")}</p>
                </div>
                <div className='mt-[110px] xl:mt-[0px]'>
                    <h1 className='open-sans text-[20px] sm:text-[25px] xl:text-[35px] text-white font-extrabold'>{t("info.section3")} </h1>
                    <p className='mt-[30px] font-light xl:w-[525px] sm:w-[688px] w-[339px] text-white text-[14px] sm:text-[16px] xl:text-[18px]'>{t("info.section7")}</p>
                </div>
            </div></Fade>
            <Fade bottom><div className='flex justify-between flex-col xl:flex-row z-10 relative mt-[110px] xl:mt-[180px]'>
                <div>
                    <h1 className='open-sans text-[20px] sm:text-[25px] xl:text-[35px] text-white font-extrabold'>{t("info.section4")} </h1>
                    <p className='mt-[30px] font-light xl:w-[525px] sm:w-[688px] w-[339px] text-white text-[14px] sm:text-[16px] xl:text-[18px]'>{t("info.section6")}</p>
                </div>
                <div className='mt-[70px] xl:mt-[0px]'>
                    <p className='mt-[30px] xl:mt-[80px] font-light xl:w-[525px] sm:w-[688px] w-[339px] text-white text-[14px] sm:text-[16px] xl:text-[18px]'>{t("info.section1")}</p>
                </div>
            </div></Fade>
            <Fade bottom><div className='flex justify-between flex-col xl:flex-row z-10 relative mt-[110px] pb-[110px] xl:mt-[180px] xl:pb-[180px]'>
                <div>
                    <h1 className='open-sans text-[20px] sm:text-[25px] xl:text-[35px] text-white font-extrabold'>{t("info.section8")}</h1>
                    <p className='mt-[30px] font-light xl:w-[525px] sm:w-[688px] w-[339px] text-white text-[14px] sm:text-[16px] xl:text-[18px]'>{t("info.section13")}</p>
                </div>
                <div className='mt-[110px] xl:mt-[0px]'>
                    <h1 className='open-sans text-[20px] sm:text-[25px] xl:text-[35px] text-white font-extrabold'>{t("info.section9")} </h1>
                    <p className='mt-[30px] font-light xl:w-[525px] sm:w-[688px] w-[339px] text-white text-[14px] sm:text-[16px] xl:text-[18px]'>{t("info.section11")}</p>
                </div>
            </div></Fade>
        </div>
        <Fade bottom duration={2000}><img src={infotwo} alt="" className='imgg z-0 bottom-0 h-[1162px] xl:h-[977px] w-full hidden md:block'/></Fade>
        <Fade bottom duration={2000}><img src={imggggg} alt="" className='imgg z-0 bottom-0 h-[1162px] xl:h-[977px] w-full block md:hidden'/></Fade>
    </div>
  );
};

export default Info;