import React, { useEffect } from 'react';

import Main from '../components/Accounts/Main'
import Explore from '../components/Accounts/Explore'
import Switcher from '../components/Accounts/Switcher'
import HowTo from '../components/Home/HowTo'
import OpenOnline from '../components/Accounts/OpenOnline'

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='bg-black z-0 relative'>
      <Main />
      <Switcher />
      <OpenOnline />
      <HowTo />
    </div>
  );
};

export default Home;