import {  useTranslation } from 'react-i18next';
import React, {useEffect} from 'react';

import phoro from '../assets/img/kyc.jpg'

import card from '../assets/img/card.png'
import app from '../assets/img/app.png'

const KYC = () => {
  const { t } = useTranslation();
  useEffect(() => {
    //window.scrollTo(0, 0);
  }, []);
  return (
    <div className='bg-black z-10 relative xl:mt-[80px] overflow-hidden'>
        <div className='h-[455px] bg-white hidden xl:flex overflow-hidden relative'>
          <div className='w-1/2 h-full bgbgbgbgfd relative z-10 flex flex-col justify-center items-end pr-[170px]'>
            <div>
              <div className='flex'>
                <div className='mt-[8px] w-[11px] h-[11px] bg-[#82279E]'></div>
                <p className='ml-[7px] text-white font-bold text-[18px] inter'>{t("kyc.1")}</p>
              </div>
              <h1 className='plus-jakarta text-[60px] text-white font-extrabold'>{t("kyc.2")}</h1>
            </div>
          </div>
          <img src={phoro} alt="" className='absolute w-[1920px] h-full'/>
        </div>
        <div className='flex flex-col xl:hidden'>
          <img src={phoro} alt="" className='object-cover'/>
          <div className='mx-[20px] mt-[40px]'>
            <div className='flex'>
              <div className='mt-[8px] w-[11px] h-[11px] bg-[#82279E]'></div>
              <p className='ml-[7px] text-white font-bold text-[18px] inter'>{t("kyc.1")}</p>
            </div>
            <h1 className='plus-jakarta text-[20px] mt-[10px] text-white font-extrabold'>{t("kyc.2")} </h1>
          </div>
        </div>
        <div className='max-w-[1280px] xl:mx-auto mt-[55px] mx-[20px]'>
            <div className='flex flex-col xl:flex-row justify-between'>
                <div className='max-w-[730px]'>
                    <h1 className='text-white open-sans font-extrabold text-[20px] xl:text-[30px]'>{t("kyc.3")}</h1>
                    <p className='text-white inter text-[14px] xl:text-[16px] mt-[15px]'>{t("kyc.4")}</p>
                    <h1 className='text-white open-sans font-extrabold text-[20px] xl:text-[30px] mt-[30px]'>{t("kyc.5")}</h1>
                    <p className='text-white inter text-[14px] xl:text-[16px] mt-[15px]'>{t("kyc.6")}</p>
                </div>
                <div className='xl:w-[275px] xl:h-[440px] bg-[#414040] flex flex-col mt-[30px] xl:mt-0'> 
                    <h1 className='mt-[20px] open-sans text-[20px] xl:text-[25px] text-[#E07DFF] font-bold text-center'>{t("kyc.7")}</h1>
                    <a href=""><p className='mx-[20px] mt-[20px] text-white opacity-70'>{t("kyc.8")}</p></a>
                    <a href=""><p className='mx-[20px] mt-[10px] text-white opacity-70'>{t("kyc.9")}</p></a>
                    <a href=""><p className='pb-[25px] xl:pb-0 mx-[20px] mt-[10px] text-white opacity-70'>{t("kyc.10")}</p></a>
                </div>
            </div>
            <div className='flex flex-col xl:flex-row items-center justify-between mt-[80px] xl:mt-[155px]'>
                <div>
                    <img src={card} alt="" />
                </div>
                <div className='max-w-[730px] mt-[30px] xl:mt-0'>
                    <h1 className='text-white open-sans font-extrabold text-[20px] xl:text-[30px]'>{t("kyc.11")}</h1>
                    <p className='text-white inter text-[14px] xl:text-[16px] mt-[15px]'>{t("kyc.12")}</p>
                    <h1 className='text-white open-sans font-extrabold text-[20px] xl:text-[30px] mt-[30px]'>{t("kyc.13")}</h1>
                    <p className='text-white inter text-[14px] xl:text-[16px] mt-[15px]'>{t("kyc.14")}</p>
                    <h1 className='text-white open-sans font-extrabold text-[20px] xl:text-[30px] mt-[30px]'>{t("kyc.15")}</h1>
                    <p className='text-white inter text-[14px] xl:text-[16px] mt-[15px]'>{t("kyc.16")}</p>
                </div>
            </div>
            <div className='flex flex-col xl:flex-row items-center justify-between mt-[30px] xl:mt-[155px] pb-[80px] xl:pb-[160px]'>
                <div className='max-w-[730px]'>
                    <h1 className='text-white open-sans font-extrabold text-[20px] xl:text-[30px]'>{t("kyc.17")}</h1>
                    <p className='text-white inter text-[14px] xl:text-[16px] mt-[15px]'>{t("kyc.18")}</p>
                    <h1 className='text-white open-sans font-extrabold text-[20px] xl:text-[30px] mt-[30px]'>{t("kyc.19")}</h1>
                    <p className='text-white inter text-[14px] xl:text-[16px] mt-[15px]'>{t("kyc.20")}</p>
                    <h1 className='text-white open-sans font-extrabold text-[20px] xl:text-[30px] mt-[30px]'>{t("kyc.21")}</h1>
                    <p className='text-white inter text-[14px] xl:text-[16px] mt-[15px]'>{t("kyc.22")}</p>
                    <h1 className='text-white open-sans font-extrabold text-[20px] xl:text-[30px] mt-[30px]'>{t("kyc.23")}</h1>
                    <p className='text-white inter text-[14px] xl:text-[16px] mt-[15px]'>{t("kyc.24")}</p>
                    <p className='text-white inter text-[14px] xl:text-[16px] mt-[15px]'>{t("kyc.25")}</p>
                    <p className='text-white inter text-[14px] xl:text-[16px] mt-[5px]'>{t("kyc.26")}</p>
                </div>
                <div className='mt-[30px] xl:mt-0'>
                    <img src={app} alt="" />
                </div>
            </div>
        </div>
    </div>
  );
};

export default KYC;