import React from 'react';
import Fade from 'react-reveal/Fade';
import { useTranslation } from "react-i18next";
import Vector from '../../assets/img/Vector.png';
import p01 from '../../assets/img/01.png';

const NoPrevious = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className='bg-black pb-[431px] hidden 2xl:block'>
                <Fade bottom delay={300}><h1 className='open-sans text-[20px] sm:text-[28px] xl:text-[35px] text-white font-extrabold text-center w-[340px] md:w-[662px] xl:w-full'>{t('1_no_experience_title')}</h1>
                <p className='montserrat sm:text-[16px] xl:text-[18px] text-center mt-[30px] font-light text-white'>{t('2_copy_professionals')}</p>
                </Fade>
                <div className='flex justify-center'>
                    <Fade bottom delay={300}><img src={Vector} className='imgg' alt="" /></Fade>
                    <img src={p01} className='imggg' alt="" />
                </div>
                <div className='flex flex-col container mx-auto'>
                    <div className='flex z-10 mx-auto'>
                        <Fade>
                        <div className='w-[510px] h-[224px] bg-prev mt-[115px] mr-[]'>
                            <h1 className='montserrat text-[23px] text-white font-bold mt-[50px] ml-[50px]'>{t('3_registration_title')}</h1>
                            <p className='montserrat text-[18px] mt-[15px] font-light text-white ml-[50px]'>{t('4_registration_description')}</p>
                        </div>
                        </Fade>
                        <Fade delay={300}>
                        <div className='w-[510px] h-[224px] bg-prev mt-[323px] ml-[200px]'>
                            <h1 className='montserrat text-[23px] text-white font-bold mt-[50px] ml-[50px]'>{t('5_activate_account_title')}</h1>
                            <p className='montserrat text-[18px] mt-[15px] font-light text-white ml-[50px] mr-[103px]'>{t('6_activate_account_description')}</p>
                        </div>
                        </Fade>
                    </div>
                    <Fade delay={600}>
                    <div className='w-[510px] h-[224px] bg-prev absolute mt-[526px] ml-[158px]'>
                            <h1 className='montserrat text-[23px] text-white font-bold mt-[50px] ml-[50px]'>{t('7_start_following_title')}</h1>
                            <p className='montserrat text-[18px] mt-[15px] font-light text-white ml-[50px] mr-[103px]'>{t('8_start_following_description')}</p>
                    </div>
                    </Fade>           
                </div>
            </div>
            <div className='bg-black block 2xl:hidden pb-[110px] sm:pb-[140px]'>
                <Fade bottom delay={300}><h1 className='open-sans text-[20px] sm:text-[28px] xl:text-[35px] w-[340px] md:w-[662px] mx-auto text-white font-extrabold text-center'>{t('1_no_experience_title')}</h1>
                    <p className='montserrat text-[14px] sm:text-[16px] xl:text-[18px] text-center mt-[30px] font-light text-white'>{t('2_copy_professionals')}</p>
                    </Fade>
                <Fade bottom delay={600}> <div className='mt-[50px] sm:mt-[65px] ml-[10%]'>
                    <h1 className='montserrat text-[16px] text-white font-extrabold'>{t('3_registration_title')}</h1>
                    <p className='montserrat text-[14px] sm:text-[16px] xl:text-[18px] mt-[15px] font-light text-white'>{t('4_registration_description')}</p>
                </div></Fade>
                <Fade bottom delay={900}><div className='mt-[30px] ml-[10%]'>
                    <h1 className='montserrat text-[16px] text-white font-extrabold'>{t('5_activate_account_title')}</h1>
                    <p className='montserrat text-[14px] sm:text-[16px] xl:text-[18px] mt-[15px] font-light text-white'>{t('6_activate_account_description')}</p>
                </div></Fade>
                <Fade bottom delay={1200}><div className='mt-[30px] ml-[10%]'>
                    <h1 className='montserrat text-[16px] text-white font-extrabold'>{t('7_start_following_title')}</h1>
                    <p className='montserrat text-[14px] sm:text-[16px] xl:text-[18px] mt-[15px] font-light text-white'>{t('8_start_following_description')}</p>
                </div></Fade>            
            </div>
        </>
    );
};

export default NoPrevious;
