import React from "react";
import Fade from 'react-reveal/Fade';
import { useTranslation } from "react-i18next";

const TradeTrust = () => {
    const { t } = useTranslation();

    return (
        <div className="bg-black">
           <Fade bottom><h1 className='open-sans text-[20px] sm:text-[28px] xl:text-[35px] text-white font-extrabold text-center'>{t('trade_trust_title')}</h1></Fade>
           <div className="flex">
           <div className="flex flex-col xl:flex-row mx-auto justify-center gap-[50px] mt-[50px] sm:mt-[100px] xl:pb-[190px] pb-[110px]  md:hidden xl:flex">
                <Fade left><div className="w-[373px] h-[240px] xl:h-[350px] bg-[#9F44BB]">
                    <h1 className='mt-[36px] ml-[30px] mr-[30px] montserrat text-[18px] sm:text-[20px] xl:text-[30px] text-white font-extrabold'>{t('fsc_title')}</h1>
                    <p className="mt-[25px] ml-[30px] mr-[30px] text-white montserrat text-[14px] sm:text-[16px] xl:text-[18px] font-light">{t('fsc_description_1')}<br/>{t('fsc_description_2')}</p>
                </div></Fade>
                <Fade bottom><div className="w-[373px] h-[240px] xl:h-[350px] bg-[#9F44BB]">
                    <h1 className='mt-[36px] ml-[30px] mr-[30px] montserrat text-[18px] sm:text-[20px] xl:text-[30px] text-white font-extrabold'>{t('aig_title')}</h1>
                    <p className="mt-[25px] ml-[30px] mr-[30px] text-white montserrat text-[14px] sm:text-[16px] xl:text-[18px] font-light">{t('aig_description_2')}</p>
                </div></Fade>
                <Fade right><div className="w-[373px] h-[240px] xl:h-[350px] bg-[#9F44BB]">
                    <h1 className='mt-[36px] ml-[30px] mr-[30px] montserrat text-[18px] sm:text-[20px] xl:text-[30px] text-white font-extrabold'>{t('us_patent_title')}</h1>
                    <p className="mt-[25px] ml-[30px] mr-[30px] text-white montserrat text-[14px] sm:text-[16px] xl:text-[18px] font-light">{t('us_patent_description_1')}<br/>{t('us_patent_description_2')}</p>
                </div></Fade>
           </div>
           </div>

           {/* {Tablet Block} */}
           <div className="pb-[155px] pt-[50px] hidden md:block xl:hidden">
           <div className="flex justify-center mt-[20px]">
                <Fade left><div className="w-[334px] h-[280px] bg-[#9F44BB] mx-auto">
                    <h1 className='mt-[30px] ml-[30px] mr-[30px] montserrat text-[20px] text-white font-extrabold'>{t('fsc_title')}</h1>
                    <p className="mt-[25px] ml-[30px] mr-[30px] text-white montserrat text-[16px] font-light">{t('fsc_description_1')}<br/>{t('fsc_description_2')}</p>
                </div></Fade>
                <Fade bottom><div className="w-[334px] h-[280px] bg-[#9F44BB] mx-auto">
                    <h1 className='mt-[30px] ml-[30px] mr-[30px] montserrat text-[20px] text-white font-extrabold'>{t('aig_title')}</h1>
                    <p className="mt-[25px] ml-[30px] mr-[30px] text-white montserrat text-[16px] font-light">{t('aig_description_1')}<br/>{t('aig_description_2')}</p>
                </div></Fade>
           </div>
           <Fade right><div className="w-[688px] h-[280px] bg-[#9F44BB] mx-auto mt-[20px]">
            <div>
            <h1 className='pt-[30px] ml-[30px] mr-[30px] montserrat text-[20px] text-white font-extrabold'>{t('us_patent_title')}</h1>
                    <p className="mt-[25px] ml-[30px] mr-[30px] text-white montserrat text-[16px] font-light">{t('us_patent_description_1')}<br/>{t('us_patent_description_2')}</p>
            </div>
                </div></Fade>
           </div>

        </div>
      );
    };
    
export default TradeTrust;
