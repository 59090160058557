import React from 'react';

import { useState } from "react";

import swiper1 from '../../assets/img/swiper1.png'
import swiper2 from '../../assets/img/swiper2.png'
import swiper3 from '../../assets/img/32133.png'
import swiper4 from '../../assets/img/3213.png'
import swiper5 from '../../assets/img/321321.png'

import Vector from '../../assets/img/Vector.svg'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { useTranslation } from "react-i18next";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';

const Choose = () => {
    const { t } = useTranslation();
  const [swiper, setSwiper] = useState({});
  return (
    <div className='bg-black'>
        <div className='flex flex-col xl:flex-row justify-center'>
            <div className='w-[245px] sm:w-[580px] xl:w-[346px] text-center xl:text-left mx-auto xl:mx-0 pb-[50px] xl:pb-0'>
                <h1 className='open-sans text-[20px] sm:text-[28px] xl:text-[35px] text-white font-extrabold'>{t('choose_title')}</h1>
                <button className='w-[161px] h-[47px] bg-[#FFF62B] mt-[50px] rounded-lg mx-auto xl:mx-0 hidden xl:block'> 
                <a href="https://user.amberinvest.tech"><p className='text-[18px] open-sans font-light text-black'>{t('start_trading')}</p></a>
                </button>
            </div> 
            <div className='w-[334px] sm:w-[688px] xl:w-[803px] xl:ml-[71px] mx-auto xl:mx-0'>
                <Swiper
                    modules={[Navigation, Pagination, Autoplay]}
                    spaceBetween={10}
                    pagination
                    slidesPerView={2}
                    loop={true}
                    autoplay={{ delay: 3000, disableOnInteraction: false }}
                    onInit={(e) => {
                        setSwiper(e);
                    }}
                    breakpoints={{
                        368: {
                          slidesPerView: 1,
                        },
                        600: {
                          slidesPerView: 2,
                        },
                        1280: {
                          slidesPerView: 2,
                        },
                      }}
                >
 <SwiperSlide>
                        <div className='w-[334px] xl:w-[385px] h-[330px] bg-white flex flex-col overflow-hidden'>
                            <img src={swiper2} alt="" className='z-0 absolute w-[334px] xl:w-[385px]'/>
                            <div className='backdown z-10 relative containerr h-[190px]'>
                                <p className='open-sans text-[17px] font-bold ml-[30px] mt-[20px] text-white'>{t('currency_pairs')}</p>
                                <div className='flex'>
                                <div className='flex flex-col ml-[30px] mt-[20px]'>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('eur_usd')}</p>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('usd_jpy')}</p>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('gbp_usd')}</p>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('eur_gbp')}</p>
                                </div>
                                <div className='flex flex-col ml-[30px] mt-[20px]'>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('usd_cad')}</p>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('aud_usd')}</p>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('usd_chf')}</p>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('nzd_usd')}</p>
                                </div>
                                </div>
                               
                            </div>
                        </div> 
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='w-[334px] xl:w-[385px] h-[330px] bg-white flex flex-col overflow-hidden'>
                            <img src={swiper1} alt="" className='z-0 absolute w-[334px] xl:w-[385px]'/>
                            <div className='backdown z-10 relative containerr h-[190px]'>
                                <p className='open-sans text-[17px] font-bold ml-[30px] mt-[20px] text-white'>{t('indices')}</p>
                                <div className='flex'>
                                <div className='flex flex-col ml-[30px] mt-[20px]'>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('dji')}</p>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('hk50')}</p>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('nasdaq')}</p>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('sp500')}</p>
                                </div>
                                <div className='flex flex-col ml-[30px] mt-[20px]'>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('gb100')}</p>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('nikkei')}</p>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('au200')}</p>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('usdidx')}</p>
                                </div>
                                </div>
                               
                            </div>
                        </div> 
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='w-[334px] xl:w-[385px] h-[330px] bg-white flex flex-col overflow-hidden'>
                            <img src={swiper3} alt="" className='z-0 absolute w-[334px] xl:w-[385px]'/>
                            <div className='backdown z-10 relative containerr h-[190px]'>
                                <p className='open-sans text-[17px] font-bold ml-[30px] mt-[20px] text-white'>{t('stocks')}</p>
                                <div className='flex'>
                                <div className='flex flex-col ml-[30px] mt-[20px]'>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('apple')}</p>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('facebook')}</p>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('google')}</p>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('tesla')}</p>
                                </div>
                                <div className='flex flex-col ml-[30px] mt-[20px]'>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('microsoft')}</p>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('sony')}</p>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('xiaomi')}</p>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('rolls_royce_holdings_plc')}</p>
                                </div>
                                </div>
                               
                            </div>
                        </div> 
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='w-[334px] xl:w-[385px] h-[330px] bg-white flex flex-col overflow-hidden'>
                            <img src={swiper4} alt="" className='z-0 absolute w-[334px] xl:w-[385px]'/>
                            <div className='backdown z-10 relative containerr h-[190px]'>
                                <p className='open-sans text-[17px] font-bold ml-[30px] mt-[20px] text-white'>{t('precious_metals')}</p>
                                <div className='flex'>
                                <div className='flex flex-col ml-[30px] mt-[20px]'>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('gold')}</p>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('palladium')}</p>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('platinum')}</p>
                                </div>
                                <div className='flex flex-col ml-[30px] mt-[20px]'>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('silver')}</p>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('gold_euro')}</p>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('silver_euro')}</p>
                                </div>
                                </div>
                               
                            </div>
                        </div> 
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='w-[334px] xl:w-[385px] h-[330px] bg-white flex flex-col overflow-hidden'>
                            <img src={swiper5} alt="" className='z-0 absolute w-[334px] xl:w-[385px]'/>
                            <div className='backdown z-10 relative containerr h-[190px]'>
                                <p className='open-sans text-[17px] font-bold ml-[30px] mt-[20px] text-white'>{t('commodities')}</p>
                                <div className='flex'>
                                <div className='flex flex-col ml-[30px] mt-[20px]'>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('brent_oil')}</p>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('natural_gas')}</p>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('sugar')}</p>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('wti_oil')}</p>
                                </div>
                                <div className='flex flex-col ml-[30px] mt-[20px]'>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('arabica_coffee')}</p>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('cotton')}</p>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('soybean_trade')}</p>
                                    <p className='open-sans text-[16px] font-light text-white'>{t('wheat')}</p>
                                </div>
                                </div>
                               
                            </div>
                        </div> 
                    </SwiperSlide>
                    <div className='pb-[30px] xl:pb-[190px] flex justify-center gap-[100px] sm:gap-[205px] mr-[10px] mt-[20px]'>
                    <div
                    onClick={() => swiper.slidePrev()}
                    className='w-[295px] h-[64px] bg-[#F4F4F4] flex justify-center buttons-swiper'
                    >
                        <div className='flex mt-[18px]'>
                            <img src={Vector} className="rotate-180 w-[26px] h-[17px] mt-[5px]" alt="" />
                            <p className='text-black text-center text-[18px]'>Back</p>
                        </div>
                    </div>
                    
                    <div
                    onClick={() => swiper.slideNext()}
                    className='w-[295px] h-[64px] bg-[#F4F4F4] flex justify-center buttons-swiper'
                    >
                        <div className='flex mt-[18px]'>
                            <p className='text-black text-center text-[18px]'>Next</p>
                            <img src={Vector} alt="" className="w-[26px] h-[17px] mt-[5px]"/>
                        </div>
                    </div>
                </div>
                </Swiper>

            </div>
        </div>
    </div>
  );
};

export default Choose;