import React from 'react';

import { useState } from "react";

import Fade from 'react-reveal/Fade';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

import { Trans, useTranslation } from 'react-i18next';

import button from '../../assets/img/tradingconditions/button.svg'

import swiper1 from '../../assets/img/tradingconditions/swiper1.png'
import swiper2 from '../../assets/img/tradingconditions/swiper1.jpg'
import swiper3 from '../../assets/img/tradingconditions/swiper2.jpg'
import swiper4 from '../../assets/img/tradingconditions/swiper3.jpg'
import swiper5 from '../../assets/img/tradingconditions/swiper4.jpg'
import swiper6 from '../../assets/img/tradingconditions/swiper5.jpg'
import swiper7 from '../../assets/img/tradingconditions/swiper6.jpg'

const OurGreat = () => {

  const [swiper, setSwiper] = useState({});
  const [swiperr, setSwiper1] = useState({});

  const { t } = useTranslation();

  return (
    <>
        <div className='bg-black w-[1280px] mx-auto flex-col xl:block hidden'>
                <div className='flex flex-row'>
                    <Fade duration={1000} bottom><h1 className='open-sans text-[20px] sm:text-[25px] xl:text-[35px] text-white font-extrabold'>{t("ourgreat.section9")}</h1>
                    </Fade><Fade duration={1000} bottom><div className='flex ml-auto'>
                        <img
                        onClick={() => swiperr.slidePrev()}
                        src={button}
                        className='w-[40px] h-[40px] buttonswiperourgreat'
                        />
                        <img
                        onClick={() => swiperr.slideNext()}
                        src={button}
                        className='w-[40px] h-[40px] ml-[20px] rotate-180 buttonswiperourgreat'
                        />
                    </div></Fade>
                </div>
                <div className='mt-[67px]'>
                <Swiper
                        modules={[Navigation, Autoplay]}
                        slidesPerView={1}
                        centeredSlides={true}
                        spaceBetween={20}
                        loop={true}
                        autoplay={{ delay: 3000, disableOnInteraction: false }}
                        onInit={(e) => {
                            setSwiper1(e);
                        }}
                    >
                        <SwiperSlide>
                            <div className='w-[1280px] bg-[#9F44BB] h-[400px] mx-auto'>
                                <div className='flex'>
                                    <img src={swiper1} className='w-[613.48px] h-[400px]' alt="" />
                                    <div className='ml-[30px]'>
                                        <h1 className='mt-[60px] text-[#181719] text-[25px] roboto font-black'>{t("ourgreat.section2")}</h1>
                                        <p className='mt-[30px] text-[#181719] roboto w-[522px] font-light text-[16px]'>{t("ourgreat.section5")}</p>
                                        <button className='w-[161px] h-[47px] bg-black mt-[50px] rounded-lg mx-auto'> 
                                        <a href="https://user.amberinvest.tech"><p className='text-[18px] font-medium open-sans font-light text-white'>{t("ourgreat.section4")}</p></a>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className='w-[1280px] bg-[#9F44BB] h-[400px] mx-auto'>
                                <div className='flex'>
                                    <img src={swiper2} className='w-[613.48px] h-[400px]' alt="" />
                                    <div className='ml-[30px]'>
                                        <h1 className='mt-[60px] text-[#181719] text-[25px] roboto font-black'>{t("ourgreat.section16")}</h1>
                                        <p className='mt-[30px] text-[#181719] roboto w-[522px] font-light text-[16px]'>{t("ourgreat.section8")}</p>
                                        <button className='w-[161px] h-[47px] bg-black mt-[50px] rounded-lg mx-auto'> 
                                        <a href="https://user.amberinvest.tech"><p className='text-[18px] font-medium open-sans font-light text-white'>Start trading</p></a>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className='w-[1280px] bg-[#9F44BB] h-[400px] mx-auto'>
                                <div className='flex'>
                                    <img src={swiper3} className='w-[613.48px] h-[400px]' alt="" />
                                    <div className='ml-[30px]'>
                                        <h1 className='mt-[60px] text-[#181719] text-[25px] roboto font-black'>{t("ourgreat.section13")}</h1>
                                        <p className='mt-[30px] text-[#181719] roboto w-[522px] font-light text-[16px]'>{t("ourgreat.section14")}</p>
                                        <button className='w-[161px] h-[47px] bg-black mt-[50px] rounded-lg mx-auto'> 
                                        <a href="https://user.amberinvest.tech"><p className='text-[18px] font-medium open-sans font-light text-white'>Start trading</p></a>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className='w-[1280px] bg-[#9F44BB] h-[400px] mx-auto'>
                                <div className='flex'>
                                    <img src={swiper4} className='w-[613.48px] h-[400px]' alt="" />
                                    <div className='ml-[30px]'>
                                        <h1 className='mt-[60px] text-[#181719] text-[25px] roboto font-black'>{t("ourgreat.section12")}</h1>
                                        <p className='mt-[30px] text-[#181719] roboto w-[522px] font-light text-[16px]'>{t("ourgreat.section10")}</p>
                                        <button className='w-[161px] h-[47px] bg-black mt-[50px] rounded-lg mx-auto'> 
                                        <a href="https://user.amberinvest.tech"><p className='text-[18px] font-medium open-sans font-light text-white'>Start trading</p></a>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className='w-[1280px] bg-[#9F44BB] h-[400px] mx-auto'>
                                <div className='flex'>
                                    <img src={swiper5} className='w-[613.48px] h-[400px]' alt="" />
                                    <div className='ml-[30px]'>
                                        <h1 className='mt-[60px] text-[#181719] text-[25px] roboto font-black'>{t("ourgreat.section15")}</h1>
                                        <p className='mt-[30px] text-[#181719] roboto w-[522px] font-light text-[16px]'>{t("ourgreat.section7")}</p>
                                        <button className='w-[161px] h-[47px] bg-black mt-[50px] rounded-lg mx-auto'> 
                                        <a href="https://user.amberinvest.tech"><p className='text-[18px] font-medium open-sans font-light text-white'>Start trading</p></a>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className='w-[1280px] bg-[#9F44BB] h-[400px] mx-auto'>
                                <div className='flex'>
                                    <img src={swiper6} className='w-[613.48px] h-[400px]' alt="" />
                                    <div className='ml-[30px]'>
                                        <h1 className='mt-[60px] text-[#181719] text-[25px] roboto font-black'>{t("ourgreat.section11")}</h1>
                                        <p className='mt-[30px] text-[#181719] roboto w-[522px] font-light text-[16px]'>{t("ourgreat.section6")}</p>
                                        <button className='w-[161px] h-[47px] bg-black mt-[50px] rounded-lg mx-auto'> 
                                        <a href="https://user.amberinvest.tech"><p className='text-[18px] font-medium open-sans font-light text-white'>Start trading</p></a>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className='w-[1280px] bg-[#9F44BB] h-[400px] mx-auto'>
                                <div className='flex'>
                                    <img src={swiper7} className='w-[613.48px] h-[400px]' alt="" />
                                    <div className='ml-[30px]'>
                                        <h1 className='mt-[60px] text-[#181719] text-[25px] roboto font-black'>{t("ourgreat.section3")}</h1>
                                        <p className='mt-[30px] text-[#181719] roboto w-[522px] font-light text-[16px]'>{t("ourgreat.section1")}</p>
                                        <button className='w-[161px] h-[47px] bg-black mt-[50px] rounded-lg mx-auto'> 
                                        <a href="https://user.amberinvest.tech"><p className='text-[18px] font-medium open-sans font-light text-white'>Start trading</p></a>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
        </div>
        <div className='bg-black w-[334px] sm:w-[688px] mx-auto flex flex-col xl:hidden mt-[-20px]'>
            <div className='flex flex-row'>
                    <Fade duration={1000} bottom><h1 className='open-sans text-[20px] sm:text-[25px] xl:text-[35px] text-white font-extrabold'>Our Great Features</h1></Fade>
                    <Fade duration={1000} bottom><div className='flex ml-auto'>
                        <img
                        onClick={() => swiper.slidePrev()}
                        src={button}
                        className='w-[40px] h-[40px] buttonswiperourgreat'
                        />
                        <img
                        onClick={() => swiper.slideNext()}
                        src={button}
                        className='w-[40px] h-[40px] ml-[20px] rotate-180 buttonswiperourgreat'
                        />
                    </div></Fade>
                </div>
                <div className='mt-[67px]'>
                    <Swiper
                        modules={[Navigation, Autoplay]}
                        slidesPerView={2}
                        spaceBetween={0}
                        width={688}
                        loop={true}
                        autoplay={{ delay: 3000, disableOnInteraction: false }}
                        onInit={(e) => {
                            setSwiper(e);
                        }}
                        breakpoints={{
                            368: {
                              slidesPerView: 1,
                            },
                            700: {
                              slidesPerView: 2,
                            },
                          }}
                    >
                        <SwiperSlide>
                        <div className='w-[334px] bg-[#9F44BB] h-[600px] sm:h-[800px] relative'>
                            <div className='flex flex-col'>
                                <img src={swiper1} className='w-[334px] h-[226px]' alt="" />
                                <div className='ml-[30px]'>
                                    <h1 className='mt-[30px] text-[#181719] text-[20px] sm:text-[25px] roboto font-black'>Competitive Spreads:</h1>
                                    <p className='mt-[30px] text-[#181719] roboto w-[293px] font-light text-[14px] xl:text-[16px]'>Amber Invest offers competitive spreads across a wide range of financial instruments. Take advantage of tight spreads to optimize your trading outcomes and minimize trading costs. Our transparent pricing ensures that you have access to fair and competitive market conditions.</p>
                                    <button className='w-[161px] h-[47px] bg-black absolute bottom-0 rounded-lg mx-auto mb-[22px]'> 
                                    <a href="https://user.amberinvest.tech"><p className='text-[18px] font-medium open-sans font-light text-white'>Start trading</p></a>
                                    </button>
                                </div>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className='w-[334px] bg-[#9F44BB] h-[600px] sm:h-[800px] relative'>
                            <div className='flex flex-col'>
                                <img src={swiper2} className='w-[334px] h-[226px]' alt="" />
                                <div className='ml-[30px]'>
                                    <h1 className='mt-[30px] text-[#181719] text-[20px] sm:text-[25px] roboto font-black'>Wide Range of Tradable Assets:</h1>
                                    <p className='mt-[30px] text-[#181719] roboto w-[293px] font-light text-[14px] xl:text-[16px]'>Diversify your portfolio with Amber Invest's extensive selection of tradable assets. Explore Forex, stocks, commodities, indices, cryptocurrencies, and more. Gain exposure to various markets and broaden your trading opportunities.</p>
                                    <button className='w-[161px] h-[47px] bg-black absolute bottom-0 rounded-lg mx-auto mb-[22px]'> 
                                    <a href="https://user.amberinvest.tech"><p className='text-[18px] font-medium open-sans font-light text-white'>Start trading</p></a>
                                    </button>
                                </div>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className='w-[334px] bg-[#9F44BB] h-[600px] sm:h-[800px] relative'>
                            <div className='flex flex-col'>
                                <img src={swiper3} className='w-[334px] h-[226px]' alt="" />
                                <div className='ml-[30px]'>
                                    <h1 className='mt-[30px] text-[#181719] text-[20px] sm:text-[25px] roboto font-black'>Advanced Order Types:</h1>
                                    <p className='mt-[30px] text-[#181719] roboto w-[293px] font-light text-[14px] xl:text-[16px]'>Take advantage of our advanced order types to enhance your trading strategies. Utilize market orders, limit orders, stop orders, and more to execute trades with precision and flexibility. Our platform empowers you to implement your preferred trading techniques effortlessly.</p>
                                    <button className='w-[161px] h-[47px] bg-black absolute bottom-0 rounded-lg mx-auto mb-[22px]'> 
                                    <a href="https://user.amberinvest.tech"><p className='text-[18px] font-medium open-sans font-light text-white'>Start trading</p></a>
                                    </button>
                                </div>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className='w-[334px] bg-[#9F44BB] h-[600px] sm:h-[800px] relative'>
                            <div className='flex flex-col'>
                                <img src={swiper4} className='w-[334px] h-[226px]' alt="" />
                                <div className='ml-[30px]'>
                                    <h1 className='mt-[30px] text-[#181719] text-[20px] sm:text-[25px] roboto font-black'>Risk Management Tools:</h1>
                                    <p className='mt-[30px] text-[#181719] roboto w-[293px] font-light text-[14px] xl:text-[16px]'>Amber Invest prioritizes risk management. Protect your capital and manage risk effectively with our comprehensive risk management tools. Set stop-loss and take-profit levels, implement trailing stops, and employ other risk management techniques to safeguard your investments.</p>
                                    <button className='w-[161px] h-[47px] bg-black absolute bottom-0 rounded-lg mx-auto mb-[22px]'> 
                                    <a href="https://user.amberinvest.tech"><p className='text-[18px] font-medium open-sans font-light text-white'>Start trading</p></a>
                                    </button>
                                </div>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className='w-[334px] bg-[#9F44BB] h-[600px] sm:h-[800px] relative'>
                            <div className='flex flex-col'>
                                <img src={swiper5} className='w-[334px] h-[226px]' alt="" />
                                <div className='ml-[30px]'>
                                    <h1 className='mt-[30px] text-[#181719] text-[20px] sm:text-[25px] roboto font-black'>Fast Trade Execution:</h1>
                                    <p className='mt-[30px] text-[#181719] roboto w-[293px] font-light text-[14px] xl:text-[16px]'>Time is of the essence in trading, and at Amber Invest, we prioritize fast trade execution. Benefit from our advanced technology infrastructure, which ensures swift order processing and minimal latency. Execute your trades with precision and seize opportunities in real-time.</p>
                                    <button className='w-[161px] h-[47px] bg-black absolute bottom-0 rounded-lg mx-auto mb-[22px]'> 
                                    <a href="https://user.amberinvest.tech"><p className='text-[18px] font-medium open-sans font-light text-white'>Start trading</p></a>
                                    </button>
                                </div>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className='w-[334px] bg-[#9F44BB] h-[600px] sm:h-[800px] relative'>
                            <div className='flex flex-col'>
                                <img src={swiper6} className='w-[334px] h-[226px]' alt="" />
                                <div className='ml-[30px]'>
                                    <h1 className='mt-[30px] text-[#181719] text-[20px] sm:text-[25px] roboto font-black'>Transparent Pricing and Fee Structure:</h1>
                                    <p className='mt-[30px] text-[#181719] roboto w-[293px] font-light text-[14px] xl:text-[16px]'>At Amber Invest, we believe in transparency. Our pricing and fee structure are straightforward and easy to understand. We strive to provide you with a clear view of the costs associated with your trades, ensuring transparency and fairness.</p>
                                    <button className='w-[161px] h-[47px] bg-black absolute bottom-0 rounded-lg mx-auto mb-[22px]'> 
                                    <a href="https://user.amberinvest.tech"><p className='text-[18px] font-medium open-sans font-light text-white'>Start trading</p></a>
                                    </button>
                                </div>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className='w-[334px] bg-[#9F44BB] h-[600px] sm:h-[800px] relative'>
                            <div className='flex flex-col'>
                                <img src={swiper7} className='w-[334px] h-[226px]' alt="" />
                                <div className='ml-[30px]'>
                                    <h1 className='mt-[30px] text-[#181719] text-[20px] sm:text-[25px] roboto font-black'>Flexible Leverage Options:</h1>
                                    <p className='mt-[30px] text-[#181719] roboto w-[293px] font-light text-[14px] xl:text-[16px]'>Amber Invest understands that traders have diverse risk profiles and trading strategies. That's why we provide flexible leverage options to suit your individual needs. Choose the leverage level that aligns with your risk tolerance and trading style, allowing you to trade with confidence.</p>
                                    <button className='w-[161px] h-[47px] bg-black absolute bottom-0 rounded-lg mx-auto mb-[22px]'> 
                                    <a href="https://user.amberinvest.tech"><p className='text-[18px] font-medium open-sans font-light text-white'>Start trading</p></a>
                                    </button>
                                </div>
                            </div>
                        </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
        </div>
    </>

  );
};

export default OurGreat;