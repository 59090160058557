import React from 'react';

import Fade from 'react-reveal/Fade';
import img from '../../assets/img/mainn.jpg'

import Marquee from "react-fast-marquee";

import one from '../../assets/img/ticker/ticker7.png'
import two from '../../assets/img/ticker/ticker5.png'
import three from '../../assets/img/ticker/ticker3.png'
import four from '../../assets/img/ticker/ticker4.png'
import five from '../../assets/img/ticker/ticker8.png'
import six from '../../assets/img/ticker/ticker12.png'
import seven from '../../assets/img/ticker/ticker15.png'
import eight from '../../assets/img/ticker/ticker10.png'
import nine from '../../assets/img/ticker/01ticker.png'
import ten from '../../assets/img/ticker/02ticker.png'
import eleven from '../../assets/img/ticker/03ticker.png'
import twelve from '../../assets/img/ticker/04ticker.png'

import one1 from '../../assets/img/ticker/ticker11.png'
import two2 from '../../assets/img/ticker/ticker16.png'
import three3 from '../../assets/img/ticker/ticker2.png'
import four4 from '../../assets/img/ticker/123.png'
import five5 from '../../assets/img/ticker/ticker13.png'
import six6 from '../../assets/img/ticker/ticker14.png'
import seven7 from '../../assets/img/ticker/ticker9.png'
import eight8 from '../../assets/img/ticker/ticker1.png'
import eight9 from '../../assets/img/ticker/ticker6.png'

import { useTranslation } from "react-i18next";


const Main = () => {
  const { t } = useTranslation();
  return (
    <div className='pb-[0px] xl:pb-[90px]'>
      <div className="h-[744px] sm:h-[942px] xl:h-[835px] relative w-full overflow-hidden">
        <img src={img} className='h-[734px] sm:h-[942px] xl:h-[835px] absolute mainbg object-cover w-full z-0 block' alt="" />
        <div className='pt-[100px] xl:pt-[144px] relative z-10'>  
          <div className='bg-main w-[339px] sm:w-[688px] xl:w-[872px] h-[250px] xl:h-[350px] flex flex-col mx-auto'>
            <Fade duration={1000} top><h1 className='plus-jakarta text-[35px] sm:text-[45px] xl:text-[60px] text-[#9F44BB] font-extrabold mx-auto mt-[50px]'>{t('1_hero_title')}</h1></Fade>
            <Fade duration={2000} top><p className='open-sans text-[16px] sm:text-[16px] xl:text-[25px] text-center font-normal text-white mx-auto mt-[20px]'>{t('2_hero_description')}</p></Fade>
            <button className='w-[161px] h-[47px] bg-[#FFF62B] mt-[35px] sm:mt-[50px] rounded-lg mx-auto'> 
              <a href="https://user.amberinvest.tech"><p className='text-[18px] font-medium open-sans text-black'>{t('3_start_trading')}</p></a>
            </button>
          </div> 
        </div>
        <div className='flex mt-[45px] xl:mt-[80px] z-10'>
          <div className='bg-one h-[150px] mt-[190px] bottom-0 w-full'>
            <Marquee autoFill className='mt-[32px]'>
              <img src={one} alt="" className='h-[25px] mr-[40px]'/>
              <img src={two} alt="" className='h-[25px] mr-[40px]'/>
              <img src={three} alt="" className='h-[25px] mr-[40px]'/>
              <img src={four} alt="" className='h-[25px] mr-[40px]'/>
              <img src={five} alt="" className='h-[25px] mr-[40px]'/>
              <img src={six} alt="" className='h-[25px] mr-[40px]'/>
              <img src={seven} alt="" className='h-[25px] mr-[40px]'/>
              <img src={eight} alt="" className='h-[25px] mr-[40px]'/>
              <img src={nine} alt="" className='h-[25px] mr-[40px]'/>
              <img src={ten} alt="" className='h-[25px] mr-[40px]'/>
              <img src={eleven} alt="" className='h-[25px] mr-[40px]'/>
              <img src={twelve} alt="" className='h-[25px] mr-[40px]'/>
            </Marquee>
            <Marquee autoFill className='mt-[35px]' direction='right'>
              <img src={one1} alt="" className='h-[25px] mr-[40px]'/>
              <img src={two2} alt="" className='h-[25px] mr-[40px]'/>
              <img src={three3} alt="" className='h-[25px] mr-[40px]'/>
              <img src={four4} alt="" className='h-[25px] mr-[40px]'/>
              <img src={five5} alt="" className='h-[25px] mr-[40px]'/>
              <img src={six6} alt="" className='h-[25px] mr-[40px]'/>
              <img src={seven7} alt="" className='h-[25px] mr-[40px]'/>
              <img src={eight8} alt="" className='h-[25px] mr-[40px]'/>
              <img src={eight9} alt="" className='h-[25px] mr-[40px]'/>
            </Marquee>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;