import React from 'react';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';
import img from '../../assets/img/img(1).png'
import phone from '../../assets/img/accounts/pgone.png'

const Main = () => {
  const { t } = useTranslation();

  return (
        <div className='pb-[0px] xl:pb-[90px] flex xl:pt-[100px]'>
        <img src={img} alt="" className="imgggg animatedimgd"/>
        <div className='flex flex-col xl:flex-row mx-auto relative z-10'>
          <div className="relative w-full xl:w-[525px] pb-[70px] md:pb-0">
            <div className='pt-[25px] sm:pt-[125px] xl:pt-[120px]'>  
              <div className='h-[500px] xl:h-[600px] flex flex-col mx-auto'>
                <Fade duration={1000} top><h1 className='plus-jakarta text-[20px] sm:text-[28px] xl:text-[35px] text-white font-extrabold mt-[50px] mx-auto xl:mx-0 text-center xl:text-left'>{t('(1)')}</h1></Fade>
                <Fade duration={2000} top><p className='w-[339px] sm:w-[688px] xl:w-full open-sans text-[14px] sm:text-[14px] xl:text-[16px] font-light text-white mt-[20px] text-center xl:text-left mx-auto xl:mx-0'>{t('(2)')}</p></Fade>
                <Fade duration={2000} top><p className='w-[339px] sm:w-[688px] xl:w-full open-sans text-[14px] sm:text-[14px] xl:text-[16px] font-light text-white mt-[10px] text-center xl:text-left mx-auto xl:mx-0'>{t('(3)')}</p></Fade>
                <Fade duration={2000} top><p className='w-[339px] sm:w-[688px] xl:w-full open-sans text-[14px] sm:text-[14px] xl:text-[16px] font-light text-white mt-[10px] text-center xl:text-left mx-auto xl:mx-0'>{t('(4)')}</p></Fade>
                <button className='w-[161px] h-[47px] bg-[#FFF62B] mt-[50px] rounded-lg mx-auto xl:mx-0'> 
                <a href="https://user.amberinvest.tech"><p className='text-[18px] font-medium open-sans text-black'>{t('(5)')}</p></a>
                </button>
              </div>
            </div>
          </div>
          <Fade duration={1000} top><img src={phone} alt="" className='hidden md:block pt-0 xl:pt-[100px] mx-auto xl:mx-0 xl:ml-[130px] w-[300px] h-[350px] xl:w-[518.477px] xl:h-[650px]'/></Fade>
        </div>
  
      </div>
  );
};

export default Main;
