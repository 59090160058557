import React, { useEffect } from 'react';

import Main from '../components/Trading/Main'
import Discover from '../components/Trading/Discover'
import OurGreat from '../components/Trading/OurGreat'
import Info from '../components/Trading/Info'

import LazyLoad from 'react-lazy-load';

const Trading = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='bg-black z-0 relative'>
      <Main />
      <LazyLoad><OurGreat/></LazyLoad>
      <LazyLoad><Info /></LazyLoad>
    </div>
  );
};

export default Trading;