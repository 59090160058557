import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';

const OpenOnline = () => {
  useEffect(() => {
    //window.scrollTo(0, 0);
  }, []);
  const { t } = useTranslation();

  return (
    <div className='bg-black z-0 relative pt-[111px] sm:pt-[140px] xl:pt-[190px]'>
      <Fade bottom delay={300}><h1 className='open-sans text-[20px] sm:text-[28px] xl:text-[35px] text-white font-extrabold text-center'>{t('open_online_1')}</h1></Fade>
      <div className='max-w-[1280px] mx-auto mt-[90px] flex flex-col xl:flex-row justify-between'>
        <div className='w-[275px] h-[260px] bg-[#414040]'>
          <h1 className='text-[80px] text-center text-[#E07DFF] open-sans font-extrabold'>1</h1>
          <p className='text-white font-light text-[14px] mt-[15px] text-center mx-[20px]'>{t("openonline.1")}</p>
          <p className='text-white font-light text-[14px] mt-[15px] text-center mx-[20px]'>{t("openonline.2")}</p>
        </div>
        <div className='w-[275px] h-[260px] bg-[#414040]'>
          <h1 className='text-[80px] text-center text-[#E07DFF] open-sans font-extrabold'>2</h1>
          <p className='text-white font-light text-[14px] mt-[15px] text-center mx-[20px]'>{t("openonline.3")}</p>
          <p className='text-white font-light text-[14px] mt-[15px] text-center mx-[20px]'>{t("openonline.4")}</p>
        </div>
        <div className='w-[275px] h-[260px] bg-[#414040]'>
          <h1 className='text-[80px] text-center text-[#E07DFF] open-sans font-extrabold'>3</h1>
          <p className='text-white font-light text-[14px] mt-[15px] text-center mx-[20px]'>{t("openonline.5")}</p>
          <p className='text-white font-light text-[14px] mt-[15px] text-center mx-[20px]'>{t("openonline.6")}</p>
        </div>
        <div className='w-[275px] h-[260px] bg-[#414040]'>
          <h1 className='text-[80px] text-center text-[#E07DFF] open-sans font-extrabold'>4</h1>
          <p className='text-white font-light text-[14px] mt-[15px] text-center mx-[20px]'>{t("openonline.7")}</p>
          <p className='text-white font-light text-[14px] mt-[15px] text-center mx-[20px]'>{t("openonline.8")}</p>
        </div>
      </div>
    </div>
  );
};

export default OpenOnline;
