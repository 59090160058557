import React from 'react';

import img from "../../assets/img/img(1).png"
import iphone from '../../assets/img/tradingconditions/img(1).png'

import Fade from 'react-reveal/Fade';


import { Trans, useTranslation } from 'react-i18next';


const Main = () => {
  const { t } = useTranslation();
  return (
    <div className='flex'>
    <img src={img} alt="" className="imgggg animatedimgd"/>
    <div className='flex flex-col xl:flex-row mx-auto relative z-10'>
      <div className="relative w-full w-[360px] xl:w-[525px]">
        <div className='pt-[25px] sm:pt-[125px] xl:pt-[120px]'>  
          <div className='h-[500px] xl:h-[700px] flex flex-col mx-auto'>
            <Fade duration={1000} top><h1 className='plus-jakarta text-[20px] sm:text-[28px] xl:text-[35px] text-white font-extrabold mt-[50px] mx-auto xl:mx-0 text-center xl:text-left'>{t("main.section1")}</h1></Fade>
            <Fade duration={2000} top><p className='w-[339px] sm:w-[688px] xl:w-[525px] open-sans text-[14px] sm:text-[16px] xl:text-[18px] font-light text-white mt-[20px] text-center xl:text-left mx-auto xl:mx-0'>{t("main.section3")} </p></Fade>
            <button className='w-[161px] h-[47px] bg-[#FFF62B] mt-[50px] rounded-lg mx-auto xl:mx-0'> 
            <a href="https://user.amberinvest.tech"><p className='text-[18px] font-medium open-sans font-light text-black'>{t("main.section2")}</p></a>
            </button>
          </div>
        </div>
      </div>
      <Fade duration={1000} top><img src={iphone} alt="" className='pt-0 xl:pt-[100px] mx-auto xl:mx-0 xl:ml-[130px] w-[300px] h-[250px] xl:w-[600px] xl:h-[493px] hidden xl:block mt-[70px]'/></Fade>
    </div>

  </div>
  );
};

export default Main;