import React, { useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import { Trans, useTranslation } from 'react-i18next';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';

import swiper1 from '../../assets/img/edication/swiper.jpg'
import swiper2 from '../../assets/img/edication/swiper2.jpg'
import swiper3 from '../../assets/img/edication/swiper3.jpg'
import swiper4 from '../../assets/img/edication/swiper4.jpg'

const SwiperMod = () => {
  const [swiper, setSwiper] = useState({});

  const { t } = useTranslation();
  return (
    <>
    <div className='max-w-[1280px] mx-auto mt-[100px]'>
        <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={10}
            slidesPerView={1}
            loop={true}
            onInit={(e) => {
                setSwiper(e);
            }}
        >
            <SwiperSlide>
                <div className='w-full xl:w-[1220px] xl:h-[350px]:'>
                    <div className='flex flex-col xl:flex-row  mx-[20px]'>
                        <div className='xl:w-[525px] xl:h-[350px]'>
                            <img src={swiper1} alt=""/>
                        </div>
                        <div className='mt-[20px] xl:mt-0 xl:ml-[170px]'>
                            <h1 className='open-sans text-white text-[20px] xl:text-[30px] font-extrabold'>
                                <Trans>
                                {t("swiper.section12")}
                                </Trans>
                            </h1>
                            <p className='montserrat text-white font-bold text-[14px] xl:text-[18px] mt-[30px]'>{t("swiper.section1")}</p>
                            <p className='montserrat text-white font-light text-[14px] xl:text-[18px] mt-[20px] max-w-[525px]'>{t("swiper.section2")}</p>    
                        </div>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='w-full xl:w-[1220px] xl:h-[350px]:'>
                    <div className='flex flex-col xl:flex-row  mx-[20px]'>
                        <div className='xl:w-[525px] xl:h-[350px]'>
                            <img src={swiper2} alt=""/>
                        </div>
                        <div className='mt-[20px] xl:mt-0 xl:ml-[170px]'>
                            <h1 className='open-sans text-white text-[20px] xl:text-[30px] font-extrabold'>                                
                                <Trans>
                                {t("swiper.section12")}
                                </Trans>
                                </h1>
                            <p className='montserrat text-white font-bold text-[14px] xl:text-[18px] mt-[30px]'>{t("swiper.section10")}</p>
                            <p className='montserrat text-white font-light text-[14px] xl:text-[18px] mt-[20px] max-w-[525px]'>{t("swiper.section3")}</p>    
                        </div>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='w-full xl:w-[1220px] xl:h-[350px]:'>
                    <div className='flex flex-col xl:flex-row  mx-[20px]'>
                        <div className='xl:w-[525px] xl:h-[350px]'>
                            <img src={swiper3} alt=""/>
                        </div>
                        <div className='mt-[20px] xl:mt-0 xl:ml-[170px]'>
                            <h1 className='open-sans text-white text-[20px] xl:text-[30px] font-extrabold'><Trans>{t("swiper.section12")}</Trans></h1>
                            <p className='montserrat text-white font-bold text-[14px] xl:text-[18px] mt-[30px]'>{t("swiper.section7")}</p>
                            <p className='montserrat text-white font-light text-[14px] xl:text-[18px] mt-[20px] max-w-[525px]'>{t("swiper.section11")}</p>    
                        </div>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='w-full xl:w-[1220px] xl:h-[350px]:'>
                    <div className='flex flex-col xl:flex-row  mx-[20px]'>
                        <div className='xl:w-[525px] xl:h-[350px]'>
                            <img src={swiper4} alt=""/>
                        </div>
                        <div className='mt-[20px] xl:mt-0 xl:ml-[170px]'>
                            <h1 className='open-sans text-white text-[20px] xl:text-[30px] font-extrabold'><Trans>{t("swiper.section12")}</Trans></h1>
                            <p className='montserrat text-white font-bold text-[14px] xl:text-[18px] mt-[30px]'>{t("swiper.section8")}</p>
                            <p className='montserrat text-white font-light text-[14px] xl:text-[18px] mt-[20px] max-w-[525px]'>{t("swiper.section6")}</p>    
                        </div>
                    </div>
                </div>
            </SwiperSlide>
            <div className='flex justify-center gap-[20px] absolute right-[20px] top-[280px] xl:top-[340px] xl:right-[520px] bottom-[80px] z-10'>
                <div
                onClick={() => swiper.slidePrev()}
                className='w-[30px] h-[30px] bg-black '
                >
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_492_3087)">
                            <path d="M2.69531 29.1504L27.3047 29.1504C27.7942 29.1504 28.2637 28.9559 28.6098 28.6098C28.9559 28.2637 29.1504 27.7942 29.1504 27.3047L29.1504 2.69531C29.1504 2.2058 28.9559 1.73634 28.6098 1.3902C28.2637 1.04407 27.7942 0.849609 27.3047 0.849609L2.69531 0.849607C2.2058 0.849607 1.73634 1.04406 1.39021 1.3902C1.04407 1.73634 0.849612 2.2058 0.849612 2.69531L0.84961 27.3047C0.849609 27.7942 1.04407 28.2637 1.3902 28.6098C1.73634 28.9559 2.2058 29.1504 2.69531 29.1504ZM2.08008 2.69531C2.08008 2.53214 2.1449 2.37565 2.26028 2.26027C2.37566 2.1449 2.53215 2.08008 2.69531 2.08008L27.3047 2.08008C27.4679 2.08008 27.6243 2.1449 27.7397 2.26027C27.8551 2.37565 27.9199 2.53214 27.9199 2.69531L27.9199 27.3047C27.9199 27.4679 27.8551 27.6243 27.7397 27.7397C27.6243 27.8551 27.4679 27.9199 27.3047 27.9199L2.69531 27.9199C2.53214 27.9199 2.37565 27.8551 2.26028 27.7397C2.1449 27.6243 2.08008 27.4679 2.08008 27.3047L2.08008 2.69531ZM8.41238 15.4353C8.35518 15.3781 8.3098 15.3103 8.27884 15.2356C8.24787 15.1609 8.23194 15.0808 8.23194 15C8.23194 14.9191 8.24787 14.8391 8.27884 14.7644C8.3098 14.6897 8.35518 14.6219 8.41238 14.5647L13.3343 9.64285C13.4497 9.5274 13.6063 9.46255 13.7695 9.46255C13.9328 9.46255 14.0894 9.5274 14.2048 9.64285C14.3203 9.75829 14.3851 9.91486 14.3851 10.0781C14.3851 10.2414 14.3203 10.398 14.2048 10.5134L10.3334 14.3848L21.1523 14.3848C21.3155 14.3848 21.472 14.4496 21.5874 14.565C21.7028 14.6803 21.7676 14.8368 21.7676 15C21.7676 15.1632 21.7028 15.3197 21.5874 15.435C21.472 15.5504 21.3155 15.6152 21.1523 15.6152L10.3334 15.6152L14.2048 19.4866C14.3203 19.602 14.3851 19.7586 14.3851 19.9219C14.3851 20.0851 14.3203 20.2417 14.2048 20.3572C14.0894 20.4726 13.9328 20.5375 13.7695 20.5375C13.6063 20.5375 13.4497 20.4726 13.3343 20.3572L8.41238 15.4353Z" fill="#AAAAAA"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_492_3087">
                                <rect width="30" height="30" fill="white" transform="translate(30 30) rotate(-180)"/>
                            </clipPath>
                        </defs>
                    </svg> 
                </div>
                <div
                onClick={() => swiper.slideNext()} 
                className='w-[30px] h-[30px] bg-black ' 
                >
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_492_3085)">
                            <path d="M27.3047 0.849609H2.69531C2.2058 0.849609 1.73634 1.04407 1.3902 1.3902C1.04407 1.73634 0.849609 2.2058 0.849609 2.69531V27.3047C0.849609 27.7942 1.04407 28.2637 1.3902 28.6098C1.73634 28.9559 2.2058 29.1504 2.69531 29.1504H27.3047C27.7942 29.1504 28.2637 28.9559 28.6098 28.6098C28.9559 28.2637 29.1504 27.7942 29.1504 27.3047V2.69531C29.1504 2.2058 28.9559 1.73634 28.6098 1.3902C28.2637 1.04407 27.7942 0.849609 27.3047 0.849609ZM27.9199 27.3047C27.9199 27.4679 27.8551 27.6243 27.7397 27.7397C27.6243 27.8551 27.4679 27.9199 27.3047 27.9199H2.69531C2.53214 27.9199 2.37566 27.8551 2.26028 27.7397C2.1449 27.6243 2.08008 27.4679 2.08008 27.3047V2.69531C2.08008 2.53214 2.1449 2.37566 2.26028 2.26028C2.37566 2.1449 2.53214 2.08008 2.69531 2.08008H27.3047C27.4679 2.08008 27.6243 2.1449 27.7397 2.26028C27.8551 2.37566 27.9199 2.53214 27.9199 2.69531V27.3047ZM21.5876 14.5647C21.6448 14.6219 21.6902 14.6897 21.7212 14.7644C21.7521 14.8391 21.7681 14.9191 21.7681 15C21.7681 15.0809 21.7521 15.1609 21.7212 15.2356C21.6902 15.3103 21.6448 15.3781 21.5876 15.4353L16.6657 20.3572C16.5503 20.4726 16.3937 20.5375 16.2305 20.5375C16.0672 20.5375 15.9106 20.4726 15.7952 20.3572C15.6797 20.2417 15.6149 20.0851 15.6149 19.9219C15.6149 19.7586 15.6797 19.602 15.7952 19.4866L19.6666 15.6152H8.84766C8.68449 15.6152 8.528 15.5504 8.41262 15.435C8.29724 15.3197 8.23242 15.1632 8.23242 15C8.23242 14.8368 8.29724 14.6803 8.41262 14.565C8.528 14.4496 8.68449 14.3848 8.84766 14.3848H19.6666L15.7952 10.5134C15.6797 10.398 15.6149 10.2414 15.6149 10.0781C15.6149 9.91486 15.6797 9.75829 15.7952 9.64285C15.9106 9.5274 16.0672 9.46255 16.2305 9.46255C16.3937 9.46255 16.5503 9.5274 16.6657 9.64285L21.5876 14.5647Z" fill="#AAAAAA"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_492_3085">
                                <rect width="30" height="30" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg> 
                </div>
            </div>        
        </Swiper>
    </div>
    </>
  );
};

export default SwiperMod;