import {  useTranslation } from 'react-i18next';
import React, {useEffect} from 'react';

import phoro from '../assets/img/car1920(1).jpg'
import phoro1 from '../assets/img/carmob.jpg'

import iphone from '../assets/img/iphone12.png'

import Swiper from '../components/Sponsorship/Swiper'

const Partner = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='bg-black z-10 relative xl:mt-[80px] overflow-hidden'>
        <div className='h-[455px] bg-white hidden xl:flex overflow-hidden relative'>
          <div className='w-1/2 h-full bgbgbgbgfd relative z-10 flex flex-col justify-center items-end pr-[170px]'>
            <div>
              <div className='flex'>
                <div className='mt-[8px] w-[11px] h-[11px] bg-[#82279E]'></div>
                <p className='ml-[7px] text-white font-bold text-[18px] inter'>{t("partner.section8")}</p>
              </div>
              <h1 className='plus-jakarta text-[45px] text-white font-extrabold'>{t("partner.section4")}<br/>{t("partner.section1")}</h1>
              <p className='text-white inter text-[18px] max-w-[523px] font-light'>{t("partner.section6")}</p>
            </div>
          </div>
          <img src={phoro} alt="" className='absolute inset-0 h-full w-auto'/>
        </div>
        <div className='flex flex-col xl:hidden'>
          <img src={phoro1} alt="" className='object-cover'/>
          <div className='mx-[20px] mt-[40px]'>
            <div className='flex'>
              <div className='mt-[8px] w-[11px] h-[11px] bg-[#82279E]'></div>
              <p className='ml-[7px] text-white font-bold text-[18px] inter'>{t("partner.section8")}</p>
            </div>
            <h1 className='plus-jakarta text-[20px] mt-[10px] text-white font-extrabold'>{t("partner.section7")} </h1>
          </div>
        </div>
        <div className='bg-black relative z-50 mx-auto pt-[90px] xl:pt-[170px]'>
          <div className='max-w-[1280px] mx-auto'>
            <div className='flex flex-col xl:flex-row'>
              <div className='mx-[20px] xl:mx-0'>
                <h1 className='open-sans text-[20px] xl:text-[30px] text-white font-extrabold'>{t("partner.section5")}<br/>{t("partner.section3")}</h1>
                <p className='mt-[30px] montserrat text-white font-light text-[14px] xl:text-[18px] max-w-[525px]'>{t("partner.section2")}</p>
              </div>
              <div className='mt-[50px] xl:mt-0 xl:ml-auto'>
                <img src={iphone} alt="" />
              </div>
            </div>
          </div>
        </div>
        <Swiper></Swiper>
    </div>
  );
};

export default Partner;