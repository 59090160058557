import React, { useState } from 'react';

import img from "../../assets/img/img(1).png"

import Fade from 'react-reveal/Fade';

import amber from '../../assets/img/about/amber.png'

import { useTranslation } from 'react-i18next';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';

import switch1 from '../../assets/img/about/Switch(1).png'
import switch2 from '../../assets/img/about/Switch(2).png'
import switch3 from '../../assets/img/about/Switch(3).png'


const Info2 = () => {
    const { t } = useTranslation();
    const [swiper, setSwiper] = useState({});
  return (
    <div className='bg-black pb-[110px] xl:pb-[180px] '>
    <div className='w-[339px] sm:w-[688px] xl:w-[1280px] mx-auto'>
        <img src={img} alt="" className="imgggg animatedimgdd"/>
        <Fade bottom><div className='flex justify-between flex-col xl:flex-row z-10 relative mt-[110px] xl:mt-[180px]'>
            <div>
                <h1 className='open-sans text-[20px] sm:text-[25px] xl:text-[35px] text-white font-extrabold'>{t("info2.section16")}</h1>
                <p className='mt-[30px] font-light xl:w-[525px] sm:w-[688px] w-[339px] text-white text-[14px] sm:text-[16px] xl:text-[18px]'>{t("info2.section37")}</p>
                <p className='mt-[15px] font-light xl:w-[525px] sm:w-[688px] w-[339px] text-white text-[14px] sm:text-[16px] xl:text-[18px]'>{t("info2.section18")}</p>
                <p className='mt-[30px] font-light xl:w-[525px] sm:w-[688px] w-[339px] text-white text-[14px] sm:text-[16px] xl:text-[18px]'>{t("info2.section32")}</p>
            </div>
            <div className='mt-[0px] xl:mt-[0px]'>
                <div className='mt-0 xl:mt-[80px]'>
                    <p className='mt-[30px] font-light xl:w-[525px] sm:w-[688px] w-[339px] text-white text-[14px] sm:text-[16px] xl:text-[18px]'>{t("info2.section39")}</p>
                    <p className='mt-[15px] font-light xl:w-[525px] sm:w-[688px] w-[339px] text-white text-[14px] sm:text-[16px] xl:text-[18px]'>{t("info2.section41")}</p>
                    <p className='mt-[30px] font-light xl:w-[525px] sm:w-[688px] w-[339px] text-white text-[14px] sm:text-[16px] xl:text-[18px]'>{t("info2.section14")}</p>
                </div>
            </div>
        </div></Fade>
        <div className='flex flex-col xl:flex-row mt-[110px] xl:mt-[170px]'>
            <div>
                <Fade bottom delay={300}><h1 className='open-sans text-[20px] sm:text-[28px] xl:text-[35px] text-[#9F44BB] font-extrabold text-left'>{t("info2.section40")}</h1></Fade>
                <Fade bottom delay={300}><h1 className='mt-[14px] open-sans text-[20px] sm:text-[28px] xl:text-[35px] text-white font-extrabold text-left'>{t("info2.section10")}</h1></Fade>
                <p className='mt-[30px] font-light xl:w-[525px] sm:w-[688px] w-[339px] text-white text-[14px] sm:text-[16px] xl:text-[18px]'>{t("info2.section24")}</p>
            </div>
            <div className="flex ml-auto">
                <img src={amber} alt="" className='w-[482px] xl:h-[425px]'/>
            </div>
        </div>
        <Fade bottom><div className='flex flex-wrap justify-between flex-col xl:flex-row z-10 relative mt-[110px] xl:mt-[180px]'>
            <div>
                <a href="/LEI_Certificate_54930012PJDRXVYNXA27.pdf" target='_blank'><svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_465_1799)">
                        <path d="M53.334 10H6.66732C5.78326 10 4.93542 10.3512 4.31029 10.9763C3.68517 11.6014 3.33398 12.4493 3.33398 13.3333V46.6667C3.33398 47.5507 3.68517 48.3986 4.31029 49.0237C4.93542 49.6488 5.78326 50 6.66732 50H31.6673L32.6173 48.8333L34.1673 46.9333L34.0173 46.6667H6.66732V13.3333H53.334V27.6C54.6002 28.4197 55.7257 29.4383 56.6673 30.6167V13.3333C56.6673 12.4493 56.3161 11.6014 55.691 10.9763C55.0659 10.3512 54.218 10 53.334 10Z" fill="#9F44BB"/>
                        <path d="M11.666 20H39.9993V22.6667H11.666V20Z" fill="#9F44BB"/>
                        <path d="M11.666 26.6665H29.9993V29.3332H11.666V26.6665Z" fill="#9F44BB"/>
                        <path d="M11.666 38.3335H28.3327V41.0002H11.666V38.3335Z" fill="#9F44BB"/>
                        <path d="M45.7662 28.7166C43.6334 28.6997 41.5451 29.3266 39.7743 30.5153C38.0034 31.704 36.6323 33.3993 35.8402 35.3796C35.0481 37.3599 34.8718 39.5331 35.3343 41.6152C35.7969 43.6972 36.8768 45.5914 38.4329 47.05L35.1995 51L36.6995 57.0166L42.7995 49.5833C44.7304 50.1542 46.7853 50.1542 48.7162 49.5833L54.8162 57.0166L56.3162 51L53.0829 47.05C54.639 45.5914 55.7189 43.6972 56.1814 41.6152C56.644 39.5331 56.4677 37.3599 55.6756 35.3796C54.8834 33.3993 53.5123 31.704 51.7415 30.5153C49.9706 29.3266 47.8823 28.6997 45.7495 28.7166H45.7662ZM45.7662 46.5166C44.3499 46.4969 42.971 46.0589 41.803 45.2577C40.6349 44.4565 39.7298 43.3279 39.2014 42.0137C38.673 40.6995 38.5449 39.2584 38.8333 37.8716C39.1217 36.4848 39.8136 35.2143 40.8221 34.2196C41.8305 33.225 43.1105 32.5507 44.5012 32.2816C45.8918 32.0124 47.331 32.1604 48.6377 32.7069C49.9445 33.2534 51.0605 34.174 51.8455 35.3531C52.6305 36.5321 53.0494 37.9169 53.0495 39.3333C53.0319 41.2503 52.2558 43.0824 50.8908 44.4286C49.5259 45.7748 47.6833 46.5255 45.7662 46.5166Z" fill="#9F44BB"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_465_1799">
                            <rect width="60" height="60" fill="white"/>
                        </clipPath>
                    </defs>
                </svg> </a>
                <p className='mt-[10px] xl:mt-[40px] font-light xl:w-[300px] sm:w-[688px] w-[339px] text-white text-[14px] sm:text-[16px] xl:text-[18px]'><a target="_blank" href="/LEI_Certificate_54930012PJDRXVYNXA27.pdf" className='font-bold'>{t("info2.section28")}</a> <a target="_blank" href="https://www.legalentityidentifier.co.uk/leicert/54930012PJDRXVYNXA27/" className='text-[#E58FFF] font-medium'>{t("info2.section35")}</a> {t("info2.section8")}</p>
            </div>
            <div className='mt-[20px] xl:mt-0'>
                <a href="/Certificate_Confirming_Incorporation_of_Company.pdf" target='_blanl'><svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_465_1799)">
                        <path d="M53.334 10H6.66732C5.78326 10 4.93542 10.3512 4.31029 10.9763C3.68517 11.6014 3.33398 12.4493 3.33398 13.3333V46.6667C3.33398 47.5507 3.68517 48.3986 4.31029 49.0237C4.93542 49.6488 5.78326 50 6.66732 50H31.6673L32.6173 48.8333L34.1673 46.9333L34.0173 46.6667H6.66732V13.3333H53.334V27.6C54.6002 28.4197 55.7257 29.4383 56.6673 30.6167V13.3333C56.6673 12.4493 56.3161 11.6014 55.691 10.9763C55.0659 10.3512 54.218 10 53.334 10Z" fill="#9F44BB"/>
                        <path d="M11.666 20H39.9993V22.6667H11.666V20Z" fill="#9F44BB"/>
                        <path d="M11.666 26.6665H29.9993V29.3332H11.666V26.6665Z" fill="#9F44BB"/>
                        <path d="M11.666 38.3335H28.3327V41.0002H11.666V38.3335Z" fill="#9F44BB"/>
                        <path d="M45.7662 28.7166C43.6334 28.6997 41.5451 29.3266 39.7743 30.5153C38.0034 31.704 36.6323 33.3993 35.8402 35.3796C35.0481 37.3599 34.8718 39.5331 35.3343 41.6152C35.7969 43.6972 36.8768 45.5914 38.4329 47.05L35.1995 51L36.6995 57.0166L42.7995 49.5833C44.7304 50.1542 46.7853 50.1542 48.7162 49.5833L54.8162 57.0166L56.3162 51L53.0829 47.05C54.639 45.5914 55.7189 43.6972 56.1814 41.6152C56.644 39.5331 56.4677 37.3599 55.6756 35.3796C54.8834 33.3993 53.5123 31.704 51.7415 30.5153C49.9706 29.3266 47.8823 28.6997 45.7495 28.7166H45.7662ZM45.7662 46.5166C44.3499 46.4969 42.971 46.0589 41.803 45.2577C40.6349 44.4565 39.7298 43.3279 39.2014 42.0137C38.673 40.6995 38.5449 39.2584 38.8333 37.8716C39.1217 36.4848 39.8136 35.2143 40.8221 34.2196C41.8305 33.225 43.1105 32.5507 44.5012 32.2816C45.8918 32.0124 47.331 32.1604 48.6377 32.7069C49.9445 33.2534 51.0605 34.174 51.8455 35.3531C52.6305 36.5321 53.0494 37.9169 53.0495 39.3333C53.0319 41.2503 52.2558 43.0824 50.8908 44.4286C49.5259 45.7748 47.6833 46.5255 45.7662 46.5166Z" fill="#9F44BB"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_465_1799">
                            <rect width="60" height="60" fill="white"/>
                        </clipPath>
                    </defs>
                </svg> </a>
                <p className='mt-[10px] xl:mt-[40px] font-light xl:w-[300px] sm:w-[688px] w-[339px] text-white text-[14px] sm:text-[16px] xl:text-[18px]'><a target="_blank" href="/Certificate_Confirming_Incorporation_of_Company.pdf" className='text-[#E58FFF] font-medium'>{t("info2.section20")}</a><br/>{t("info2.section25")}</p>
            </div>
            <div className='mt-[20px] xl:mt-0'>
                <a href="https://www.sgpbusiness.com/company/Amber-Strategic-Investments-Pte-Ltd" target='_blank'><svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_465_1799)">
                        <path d="M53.334 10H6.66732C5.78326 10 4.93542 10.3512 4.31029 10.9763C3.68517 11.6014 3.33398 12.4493 3.33398 13.3333V46.6667C3.33398 47.5507 3.68517 48.3986 4.31029 49.0237C4.93542 49.6488 5.78326 50 6.66732 50H31.6673L32.6173 48.8333L34.1673 46.9333L34.0173 46.6667H6.66732V13.3333H53.334V27.6C54.6002 28.4197 55.7257 29.4383 56.6673 30.6167V13.3333C56.6673 12.4493 56.3161 11.6014 55.691 10.9763C55.0659 10.3512 54.218 10 53.334 10Z" fill="#9F44BB"/>
                        <path d="M11.666 20H39.9993V22.6667H11.666V20Z" fill="#9F44BB"/>
                        <path d="M11.666 26.6665H29.9993V29.3332H11.666V26.6665Z" fill="#9F44BB"/>
                        <path d="M11.666 38.3335H28.3327V41.0002H11.666V38.3335Z" fill="#9F44BB"/>
                        <path d="M45.7662 28.7166C43.6334 28.6997 41.5451 29.3266 39.7743 30.5153C38.0034 31.704 36.6323 33.3993 35.8402 35.3796C35.0481 37.3599 34.8718 39.5331 35.3343 41.6152C35.7969 43.6972 36.8768 45.5914 38.4329 47.05L35.1995 51L36.6995 57.0166L42.7995 49.5833C44.7304 50.1542 46.7853 50.1542 48.7162 49.5833L54.8162 57.0166L56.3162 51L53.0829 47.05C54.639 45.5914 55.7189 43.6972 56.1814 41.6152C56.644 39.5331 56.4677 37.3599 55.6756 35.3796C54.8834 33.3993 53.5123 31.704 51.7415 30.5153C49.9706 29.3266 47.8823 28.6997 45.7495 28.7166H45.7662ZM45.7662 46.5166C44.3499 46.4969 42.971 46.0589 41.803 45.2577C40.6349 44.4565 39.7298 43.3279 39.2014 42.0137C38.673 40.6995 38.5449 39.2584 38.8333 37.8716C39.1217 36.4848 39.8136 35.2143 40.8221 34.2196C41.8305 33.225 43.1105 32.5507 44.5012 32.2816C45.8918 32.0124 47.331 32.1604 48.6377 32.7069C49.9445 33.2534 51.0605 34.174 51.8455 35.3531C52.6305 36.5321 53.0494 37.9169 53.0495 39.3333C53.0319 41.2503 52.2558 43.0824 50.8908 44.4286C49.5259 45.7748 47.6833 46.5255 45.7662 46.5166Z" fill="#9F44BB"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_465_1799">
                            <rect width="60" height="60" fill="white"/>
                        </clipPath>
                    </defs>
                </svg> </a>
                <p className='mt-[10px] xl:mt-[40px] font-light xl:w-[300px] sm:w-[688px] w-[339px] text-white text-[14px] sm:text-[16px] xl:text-[18px]'><a target="_blank" href="https://www.sgpbusiness.com/company/Amber-Strategic-Investments-Pte-Ltd" className='text-[#E58FFF] font-medium'>{t("info2.section13")}</a><br/>{t("info2.section30")}</p>
            </div>
        </div></Fade>
        <div className='flex flex-col xl:flex-row justify-center xl:gap-[150px] xl:mt-[50px]'>
            
            <div className='mt-[20px] xl:mt-0'>
                <a href="https://iirocs.ca/sections/investors/dealers-we-regulate.htm#:~:text=Amber%20Invest%20Holding%20PTE.%20LTD" target='_blank'><svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_465_1799)">
                        <path d="M53.334 10H6.66732C5.78326 10 4.93542 10.3512 4.31029 10.9763C3.68517 11.6014 3.33398 12.4493 3.33398 13.3333V46.6667C3.33398 47.5507 3.68517 48.3986 4.31029 49.0237C4.93542 49.6488 5.78326 50 6.66732 50H31.6673L32.6173 48.8333L34.1673 46.9333L34.0173 46.6667H6.66732V13.3333H53.334V27.6C54.6002 28.4197 55.7257 29.4383 56.6673 30.6167V13.3333C56.6673 12.4493 56.3161 11.6014 55.691 10.9763C55.0659 10.3512 54.218 10 53.334 10Z" fill="#9F44BB"/>
                        <path d="M11.666 20H39.9993V22.6667H11.666V20Z" fill="#9F44BB"/>
                        <path d="M11.666 26.6665H29.9993V29.3332H11.666V26.6665Z" fill="#9F44BB"/>
                        <path d="M11.666 38.3335H28.3327V41.0002H11.666V38.3335Z" fill="#9F44BB"/>
                        <path d="M45.7662 28.7166C43.6334 28.6997 41.5451 29.3266 39.7743 30.5153C38.0034 31.704 36.6323 33.3993 35.8402 35.3796C35.0481 37.3599 34.8718 39.5331 35.3343 41.6152C35.7969 43.6972 36.8768 45.5914 38.4329 47.05L35.1995 51L36.6995 57.0166L42.7995 49.5833C44.7304 50.1542 46.7853 50.1542 48.7162 49.5833L54.8162 57.0166L56.3162 51L53.0829 47.05C54.639 45.5914 55.7189 43.6972 56.1814 41.6152C56.644 39.5331 56.4677 37.3599 55.6756 35.3796C54.8834 33.3993 53.5123 31.704 51.7415 30.5153C49.9706 29.3266 47.8823 28.6997 45.7495 28.7166H45.7662ZM45.7662 46.5166C44.3499 46.4969 42.971 46.0589 41.803 45.2577C40.6349 44.4565 39.7298 43.3279 39.2014 42.0137C38.673 40.6995 38.5449 39.2584 38.8333 37.8716C39.1217 36.4848 39.8136 35.2143 40.8221 34.2196C41.8305 33.225 43.1105 32.5507 44.5012 32.2816C45.8918 32.0124 47.331 32.1604 48.6377 32.7069C49.9445 33.2534 51.0605 34.174 51.8455 35.3531C52.6305 36.5321 53.0494 37.9169 53.0495 39.3333C53.0319 41.2503 52.2558 43.0824 50.8908 44.4286C49.5259 45.7748 47.6833 46.5255 45.7662 46.5166Z" fill="#9F44BB"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_465_1799">
                            <rect width="60" height="60" fill="white"/>
                        </clipPath>
                    </defs>
                </svg> </a>
                <p className='mt-[10px] xl:mt-[40px] font-light xl:w-[300px] sm:w-[688px] w-[339px] text-white text-[14px] sm:text-[16px] xl:text-[18px]'><a target="_blank" href="https://iirocs.ca/sections/investors/dealers-we-regulate.htm#:~:text=Amber%20Invest%20Holding%20PTE.%20LTD" className='text-[#E58FFF] font-medium'>IIROC OCRCVM</a><br/>{t("info2.section30333")}</p>
            </div>
            <div className='mt-[20px] xl:mt-0'>
                <a href="/DIEZA.pdf" target='_blank'><svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_465_1799)">
                        <path d="M53.334 10H6.66732C5.78326 10 4.93542 10.3512 4.31029 10.9763C3.68517 11.6014 3.33398 12.4493 3.33398 13.3333V46.6667C3.33398 47.5507 3.68517 48.3986 4.31029 49.0237C4.93542 49.6488 5.78326 50 6.66732 50H31.6673L32.6173 48.8333L34.1673 46.9333L34.0173 46.6667H6.66732V13.3333H53.334V27.6C54.6002 28.4197 55.7257 29.4383 56.6673 30.6167V13.3333C56.6673 12.4493 56.3161 11.6014 55.691 10.9763C55.0659 10.3512 54.218 10 53.334 10Z" fill="#9F44BB"/>
                        <path d="M11.666 20H39.9993V22.6667H11.666V20Z" fill="#9F44BB"/>
                        <path d="M11.666 26.6665H29.9993V29.3332H11.666V26.6665Z" fill="#9F44BB"/>
                        <path d="M11.666 38.3335H28.3327V41.0002H11.666V38.3335Z" fill="#9F44BB"/>
                        <path d="M45.7662 28.7166C43.6334 28.6997 41.5451 29.3266 39.7743 30.5153C38.0034 31.704 36.6323 33.3993 35.8402 35.3796C35.0481 37.3599 34.8718 39.5331 35.3343 41.6152C35.7969 43.6972 36.8768 45.5914 38.4329 47.05L35.1995 51L36.6995 57.0166L42.7995 49.5833C44.7304 50.1542 46.7853 50.1542 48.7162 49.5833L54.8162 57.0166L56.3162 51L53.0829 47.05C54.639 45.5914 55.7189 43.6972 56.1814 41.6152C56.644 39.5331 56.4677 37.3599 55.6756 35.3796C54.8834 33.3993 53.5123 31.704 51.7415 30.5153C49.9706 29.3266 47.8823 28.6997 45.7495 28.7166H45.7662ZM45.7662 46.5166C44.3499 46.4969 42.971 46.0589 41.803 45.2577C40.6349 44.4565 39.7298 43.3279 39.2014 42.0137C38.673 40.6995 38.5449 39.2584 38.8333 37.8716C39.1217 36.4848 39.8136 35.2143 40.8221 34.2196C41.8305 33.225 43.1105 32.5507 44.5012 32.2816C45.8918 32.0124 47.331 32.1604 48.6377 32.7069C49.9445 33.2534 51.0605 34.174 51.8455 35.3531C52.6305 36.5321 53.0494 37.9169 53.0495 39.3333C53.0319 41.2503 52.2558 43.0824 50.8908 44.4286C49.5259 45.7748 47.6833 46.5255 45.7662 46.5166Z" fill="#9F44BB"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_465_1799">
                            <rect width="60" height="60" fill="white"/>
                        </clipPath>
                    </defs>
                </svg> </a>
                <p className='mt-[10px] xl:mt-[40px] font-light xl:w-[300px] sm:w-[688px] w-[339px] text-white text-[14px] sm:text-[16px] xl:text-[18px]'><a target="_blank" href="https://dlp.dubai.gov.ae/Legislation%20Reference/2021/Law%20No.%20(16)%20of%202021.html" className='text-[#E58FFF] font-medium'>DIEZA Certificate</a><br/>{t("info2.section3033433")}</p>
            </div>
        </div>
        <div className='relative z-10 mt-[110px] xl:mt-[180px]'>
            <div className='flex flex-col xl:flex-row justify-between'>
                <h1 className='open-sans text-[20px] sm:text-[28px] xl:text-[35px] font-extrabold text-white'>{t("info2.section2222234")}</h1>
                <div className='flex ml-auto gap-[20px] z-10'>
                    <div
                    onClick={() => swiper.slidePrev()}
                    className='w-[30px] h-[30px] bg-black cursor-pointer'
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="white" stroke-opacity="0.5"/>
                            <path d="M11 20L16 22.8868L16 17.1132L11 20ZM29 19.5L15.5 19.5L15.5 20.5L29 20.5L29 19.5Z" fill="white" fill-opacity="0.5"/>
                        </svg>
                    </div>
                    <div
                    onClick={() => swiper.slideNext()} 
                    className='w-[30px] h-[30px] bg-black cursor-pointer' 
                    >
                       <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <rect x="39.5" y="39.5" width="39" height="39" rx="19.5" transform="rotate(180 39.5 39.5)" stroke="white" stroke-opacity="0.5"/>
                            <path d="M29 20L24 17.1132L24 22.8867L29 20ZM11 20.5L24.5 20.5L24.5 19.5L11 19.5L11 20.5Z" fill="white" fill-opacity="0.5"/>
                        </svg>
                    </div>
                </div> 
            </div>
            
            <Swiper
                modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={10}
                slidesPerView={1}
                loop={true}
                onInit={(e) => {
                    setSwiper(e);
                }}
                className='mt-[40px]'
                breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    1280: {
                      slidesPerView: 2,
                    },
                  }}
            >
                <SwiperSlide>
                    <div className='xl:w-[527px] h-[353px] bg-[#191919] rounded-[10px] mx-auto'>
                        <div className='p-[40px]'>
                            <div className='flex items-end'>
                                <img src={switch1} alt="" />
                                <p className='ml-[20px] text-[#FFFFFFB2] open-sans text-[14px] xl:text-[16px]'>Emma Nelson</p>
                            </div>
                            <p className='mt-[20px] max-w-[447px] text-[#FFFFFFB2] open-sans text-[14px] xl:text-[16px]'>{t("switch.say1")}</p> 
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-[527px] h-[353px] bg-[#191919] rounded-[10px] mx-auto'>
                        <div className='p-[40px]'>
                            <div className='flex items-end'>
                                <img src={switch2} alt="" />
                                <p className='ml-[20px] text-[#FFFFFFB2] open-sans text-[14px] xl:text-[16px]'>David Johnson</p>
                            </div>
                            <p className='mt-[20px] max-w-[447px] text-[#FFFFFFB2] open-sans text-[14px] xl:text-[16px]'>{t("switch.say2")}</p> 
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-[527px] h-[353px] bg-[#191919] rounded-[10px] mx-autof'>
                        <div className='p-[40px]'>
                            <div className='flex items-end'>
                                <img src={switch3} alt="" />
                                <p className='ml-[20px] text-[#FFFFFFB2] open-sans text-[14px] xl:text-[16px]'>Tony Wilson</p>
                            </div>
                            <p className='mt-[20px] max-w-[447px] text-[#FFFFFFB2] open-sans text-[14px] xl:text-[16px]'>{t("switch.say3")}</p> 
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
        <Fade bottom><div className='flex justify-between flex-col z-10 relative mt-[110px] xl:mt-[180px]'>
            <h1 className='open-sans text-[20px] sm:text-[28px] xl:text-[35px] font-extrabold text-white'>{t("info2.section2")}</h1>
            <div className='mt-[30px] flex xl:flex-row flex-col'>
                <div>
                    <div className='flex'>
                        <p className='w-[135px] text-[14px] xl:text-[18px] text-white montserrat text-light'>{t("info2.section21")}</p>
                        <p className='ml-[10px] text-white text-[14px] xl:text-[18px]   montserrat text-light '>Date Incorporation<br/>6 July 2017 (Thursday)</p>
                    </div>
                    <div className='flex mt-[25px]'>
                        <p className='w-[135px] text-white text-[14px] xl:text-[18px]   montserrat text-light'>{t("info2.section26")}</p>
                        <p className='ml-[10px] text-white text-[14px] xl:text-[18px]   montserrat text-light'>{t("info2.section4")}<br/> {t("info2.section22")}<br/> Singapore</p>
                    </div>
                    <div className='flex mt-[25px]'>
                        <p className='w-[135px] text-white text-[14px] xl:text-[18px]   montserrat text-light'>{t("info2.section15")}</p>
                        <p className='ml-[10px] text-white text-[14px] xl:text-[18px]   montserrat text-light'>{t("info2.section9")} <br/>{t("info2.section11")}<br/>{t("info2.section27")}<br/>{t("info2.section36")}<br/>{t("info2.section6")}<br/>{t("info2.section31")}</p>
                    </div>
                    <div className='flex mt-[25px]'>
                        <p className='w-[135px] text-white text-[14px] xl:text-[18px]   montserrat text-light'>{t("info2.section12")}</p>
                        <p className='ml-[10px] text-white text-[14px] xl:text-[18px]   montserrat text-light'>{t("info2.section7")}</p>
                    </div>
                </div>
                <div className='mt-[25px] xl:mt-0 xl:ml-[180px]'>
                    <div className='flex'>
                        <p className='w-[135px] text-white text-[14px] xl:text-[18px]   montserrat text-light'>{t("info2.section19")}</p>
                        <p className='ml-[10px] text-white text-[14px] xl:text-[18px]   montserrat text-light '>{t("info2.section29")}</p>
                    </div>
                    <div className='flex mt-[25px]'>
                        <p className='w-[135px] text-white text-[14px] xl:text-[18px]   montserrat text-light'>{t("info2.section23")}</p>
                        <p className='ml-[10px] text-white text-[14px] xl:text-[18px]   montserrat text-light'>{t("info2.section17")}</p>
                    </div>
                    <div className='flex mt-[25px]'>
                        <p className='w-[135px] text-white text-[14px] xl:text-[18px]   montserrat text-light'>{t("info2.section42")}</p>
                        <p className='ml-[10px] text-white text-[14px] xl:text-[18px]   montserrat text-light'>3</p>
                    </div>
                    <div className='flex mt-[25px]'>
                        <p className='w-[135px] text-white text-[14px] xl:text-[18px]   montserrat text-light'>{t("info2.section43")}</p>
                        <p className='ml-[10px] text-white text-[14px] xl:text-[18px]   montserrat text-light'>{t("info2.section34")}</p>
                    </div>
                    <div className='flex mt-[25px]'>
                        <p className='w-[135px] text-white text-[14px] xl:text-[18px]   montserrat text-light'>{t("info2.section1")}</p>
                        <p className='ml-[10px] text-white text-[14px] xl:text-[18px]   montserrat text-light'>{t("info2.section5")}</p>
                    </div>
                </div>
            </div>
        </div></Fade>
        <Fade bottom><div className='flex justify-between flex-col z-10 relative mt-[110px] xl:mt-[180px]'>
            <h1 className='open-sans text-[20px] sm:text-[28px] xl:text-[35px] font-extrabold text-white mb-[20px]'>{t("info2.sectionfinus")}</h1>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.774668724098!2d103.87350051105157!3d1.3105340616943868!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1836fb0887e1%3A0xe148342158be8eac!2zMTkgTG9yIDQgR2V5bGFuZywg0KHQuNC90LPQsNC_0YPRgCAzOTkyNzU!5e0!3m2!1sru!2s!4v1714554060761!5m2!1sen!2s" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div></Fade>
    </div>
</div>
  );
};

export default Info2;